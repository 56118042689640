import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";

import {
  Dropdown,
  DropdownItemProps,
  Header,
  Segment,
} from "semantic-ui-react";
import PersonsTable from "../components/persons/PersonsTable";
import SearchPerson from "../components/persons/SearchPerson";
import { ThemeContext } from "../context/ThemeContext";

const roleOptions: DropdownItemProps[] = [
  { text: "Όλοι οι καλλιτέχνες", value: "all", key: "all" },
  { text: "Συνθέτες", value: "composer", key: "composer" },
  { text: "Στιχουργοί", value: "songwriter", key: "songwriter" },
];

function Persons() {
  const { darkTheme } = useContext(ThemeContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const role = searchParams.get("role") ?? "all";
  return (
    <>
      <Helmet>
        <title>Καλλιτέχνες - Παρόλες.gr</title>
        <meta
          name="description"
          content="Αναζήτησε στίχους για τα αγαπημένα σου ελληνικά τραγούδια εύκολα και γρήγορα χωρίς διαφημίσεις!"
        />
        <meta property="og:title" content="Καλλιτέχνες - Παρόλες.gr" />
      </Helmet>
      <SearchPerson inverted={darkTheme} />
      <Segment inverted={darkTheme}>
        <Header>Βιβλιοθήκη καλλιτεχνών</Header>
        <Dropdown
          selection
          options={roleOptions}
          labeled
          value={role}
          onChange={(e, data) =>
            setSearchParams((searchParams) => {
              searchParams.set("role", data.value as string);
              searchParams.delete("offset");
              return searchParams;
            })
          }
        />
        <PersonsTable inverted={darkTheme} role={role as "all" | "composer" | "songwriter"} />
      </Segment>
    </>
  );
}

export default Persons;
