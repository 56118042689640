import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useMatches } from "react-router-dom";
import { Header, Segment } from "semantic-ui-react";

import RepertoireTable from "../components/songs/RepertoireTable";
import { ThemeContext } from "../context/ThemeContext";

function Repertoire() {
  const { darkTheme } = useContext(ThemeContext);
  const [matched] = useMatches();
  const { id } = matched.params;
  const parsedId = parseInt(id ?? "");
  return (
    <>
      <Helmet>
        <title>To ρεπερτόριό μου - Παρόλες.gr</title>
      </Helmet>
      <Segment inverted={darkTheme}>
        <Header>
          {parsedId
            ? `Το ρεπερτόριο του χρήστη ${parsedId}`
            : "To ρεπερτόριό μου"}
        </Header>
        <RepertoireTable inverted={darkTheme} userId={isNaN(parsedId) ? undefined : parsedId} />
      </Segment>
    </>
  );
}

export default Repertoire;
