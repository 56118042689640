import { useContext } from "react";
import { Table } from "semantic-ui-react";
import { getMyRepertoire, getRepertoireOfUser } from "../../api/users";
import { AuthContext } from "../../context/AuthContext";
import PaginatedTable from "../PaginatedTable";
import SongRow from "./SongRow";

interface IProps {
  userId?: number;
  inverted?: boolean;
}

function RepertoireTable(props: IProps) {
  const { user } = useContext(AuthContext);
  if (!user) {
    throw new Error("Not possible!");
  }

  const options: any = {
    token: user.token,
  };
  if (props.userId) {
    options.userId = props.userId;
  }

  return (
    <PaginatedTable
      inverted={props.inverted}
      header={
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Τραγούδι</Table.HeaderCell>
            <Table.HeaderCell width={3}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      }
      footer={<Table.HeaderCell></Table.HeaderCell>}
      row={(s) => <SongRow inverted={props.inverted} song={s} hideMutations />}
      queryFunction={!props.userId ? getMyRepertoire : getRepertoireOfUser}
      pageSize={10}
      queryKey={["songs", "repertoire"]}
      options={options}
    />
  );
}

export default RepertoireTable;
