import { Form } from "semantic-ui-react";

interface IProps {
  firstName: string;
  setFirstName: (s: string) => void;
  lastName: string;
  setLastName: (s: string) => void;
}

function PersonForm(props: IProps) {
  return (
    <Form>
      <Form.Field required>
        <label>Επώνυμο</label>
        <input
          placeholder="Επώνυμο"
          value={props.lastName}
          onChange={(ev) => props.setLastName(ev.target.value)}
        />
      </Form.Field>
      <Form.Field required>
        <label>Όνομα</label>
        <input
          placeholder="Όνομα"
          value={props.firstName}
          onChange={(ev) => props.setFirstName(ev.target.value)}
        />
      </Form.Field>
    </Form>
  );
}

export default PersonForm;
