import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Button, Header, Icon, Segment } from "semantic-ui-react";

function RegistrationSuccess() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Επιτυχής εγγραφή - Παρόλες.gr</title>
      </Helmet>
      <Segment textAlign="center">
        <Icon size="massive" name="mail" color="grey" />
        <Header>Επιτυχής εγγραφή! Έλεγξε τα εισερχόμενά σου.</Header>
        <p>
          Έλεγξε τα εισερχόμενά σου στο email εγγραφής σου και κάνε κλικ στον
          σύνδεσμο για να επιβεβαιώσεις τη διεύθυνσή σου.
        </p>
        <Button onClick={() => navigate("/")}>Πίσω στην Αρχική</Button>
      </Segment>
    </>
  );
}

export default RegistrationSuccess;
