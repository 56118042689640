import { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  Divider,
  Header,
  Segment,
  Form,
  Message,
  Dropdown,
  DropdownItemProps,
} from "semantic-ui-react";
import { changePassword } from "../api/user";
import { AuthContext } from "../context/AuthContext";
import { ThemeContext } from "../context/ThemeContext";
import { Theme } from "../hooks/useTheme";

function UserSettings() {
  const { darkTheme, setUserTheme, userTheme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const changePasswordMutation = useMutation(
    () => {
      if (!user) {
        throw new Error("Not authenticated!");
      }
      return changePassword({ token: user.token, newPassword, oldPassword });
    },
    {
      onSuccess: () => {
        toast(`Ο κωδικός άλλαξε επιτυχώς!`, {
          type: "success",
        });
      },
    }
  );

  const validatePassword = () => {
    if (confirmPassword !== newPassword) {
      return false;
    }

    return newPassword.length >= 8 && oldPassword.length > 0;
  };

  const changePasswordAction = async () => {
    await changePasswordMutation.mutateAsync();
    setNewPassword("");
    setOldPassword("");
    setConfirmPassword("");
  };

  const roleOptions: DropdownItemProps[] = [
    { text: "Φωτεινό", value: "light", key: "light" },
    { text: "Σκοτεινό", value: "dark", key: "dark" },
    { text: "Σύστημα", value: "system", key: "system" },
  ];

  const setTheme = (theme: Theme | "system") => {
    setUserTheme(theme as Theme | "system");
    if (theme !== "light") {
      toast(
        "Η σκοτεινή λειτουργία είναι ακόμα ατελής. Προχωρήστε με δική σας ευθύνη :)"
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Ρυθμίσεις χρήστη - Παρόλες.gr</title>
      </Helmet>
      <Segment inverted={darkTheme}>
        <Header>{user?.username}</Header>
        <Divider />
        <Header>Αλλαγή κωδικού</Header>
        <Form inverted={darkTheme} success={changePasswordMutation.isSuccess}>
          <Form.Group widths="equal">
            <Form.Field fluid required>
              <label>Παλιός κωδικός</label>
              <input
                placeholder="Παλιός κωδικός"
                type="password"
                value={oldPassword}
                disabled={changePasswordMutation.isLoading}
                onChange={(ev) => setOldPassword(ev.target.value)}
              />
            </Form.Field>
            <Form.Field fluid required>
              <label>Νέος κωδικός</label>
              <input
                placeholder="Νέος κωδικός"
                type="password"
                value={newPassword}
                disabled={changePasswordMutation.isLoading}
                onChange={(ev) => setNewPassword(ev.target.value)}
              />
            </Form.Field>
            <Form.Field fluid required>
              <label>Επιβεβαίωση κωδικού</label>
              <input
                placeholder="Επιβεβαίωση κωδικού"
                type="password"
                value={confirmPassword}
                disabled={changePasswordMutation.isLoading}
                onChange={(ev) => setConfirmPassword(ev.target.value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Button
            inverted={darkTheme}
            disabled={!validatePassword()}
            onClick={changePasswordAction}
            loading={changePasswordMutation.isLoading}
          >
            Αλλαγή κωδικού
          </Form.Button>
          <Message
            inverted={darkTheme}
            success
            header="Ο κωδικός άλλαξε επιτυχώς!"
          />
        </Form>
      </Segment>
      <Segment inverted={darkTheme}>
        <Header>Εμφάνιση</Header>
        <Dropdown
          selection
          options={roleOptions}
          labeled
          value={userTheme}
          onChange={(e, data) => setTheme(data.value as Theme | "system")}
        />
      </Segment>
    </>
  );
}

export default UserSettings;
