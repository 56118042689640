import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { Divider, Header, Segment, Statistic } from "semantic-ui-react";
import { getPersonCount } from "../../api/persons";
import { getSongCount } from "../../api/songs";
import ArtistShowcase from "../../components/ArtistShowcase";
import SearchPerson from "../../components/persons/SearchPerson";
import SearchSongs from "../../components/songs/SearchSongs";
import { ThemeContext } from "../../context/ThemeContext";
import "./explore.css";

function Explore() {
  const { darkTheme } = useContext(ThemeContext);
  const { data: songCount } = useQuery(["songs", "count"], () =>
    getSongCount()
  );
  const { data: artistCount } = useQuery(["persons", "count"], () =>
    getPersonCount()
  );

  return (
    <>
      <Helmet>
        <title>Αρχική σελίδα - Παρόλες.gr</title>
        <meta property="og:title" content="Αρχική σελίδα - Παρόλες.gr" />
      </Helmet>
      <Segment inverted={darkTheme} textAlign="center">
        <Header>Βρες τραγούδια και πρόσθεσέ τα στο ρεπερτόριό σου!</Header>
        <p>
          Ξεκίνα την αναζήτηση γράφοντας τον τίτλο ή ένα τμήμα από τους στίχους
          κάποιου τραγουδιού...
        </p>
        <SearchSongs style={{ width: "100%" }} size="large" fluid />
        <Header>Στατιστικά βιβλιοθήκης</Header>
        <Statistic inverted={darkTheme} size="small">
          <Statistic.Value>{songCount}</Statistic.Value>
          <Statistic.Label>Τραγουδια</Statistic.Label>
        </Statistic>
        <Statistic inverted={darkTheme} size="small">
          <Statistic.Value>{artistCount}</Statistic.Value>
          <Statistic.Label>Καλλιτεχνες</Statistic.Label>
        </Statistic>
      </Segment>
      <SearchPerson inverted={darkTheme} textAlign="center" fluid />
      <Header inverted={darkTheme} textAlign="center">Δημοφιλείς Συνθέτες</Header>
      <Divider inverted={darkTheme} />
      <ArtistShowcase inverted={darkTheme} role="composer" />
      <Header inverted={darkTheme} textAlign="center">Δημοφιλείς Στιχουργοί</Header>
      <Divider inverted={darkTheme} />
      <ArtistShowcase inverted={darkTheme} role="songwriter" />
    </>
  );
}

export default Explore;
