import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Popup } from "semantic-ui-react";
import { addToMyRepertoire, deleteFromMyRepertoire } from "../../api/users";
import { AuthContext } from "../../context/AuthContext";
import HoveredIconButton from "../HoverediconButton";

interface IProps {
  isInRepertoire: boolean;
  id: number;
  floated?: "left" | "right";
  withLabel?: boolean;
  inverted?: boolean;
}

function RepertoireToggleButton(props: IProps) {
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const removeSongFromMyRepertoireMutation = useMutation(
    (id: number) => {
      if (!user) {
        throw new Error("Not authenticated!");
      }
      return deleteFromMyRepertoire(id, user.token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("repertoire");
        queryClient.invalidateQueries("songs");
        queryClient.invalidateQueries(["song", props.id]);
      },
    }
  );

  const addSongToMyRepertoireMutation = useMutation(
    (id: number) => {
      if (!user) {
        throw new Error("Not authenticated!");
      }
      return addToMyRepertoire(id, user.token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("repertoire");
        queryClient.invalidateQueries("songs");
        queryClient.invalidateQueries(["song", props.id]);
      },
    }
  );
  return !props.isInRepertoire ? (
    <Popup
      inverted={props.inverted}
      position="bottom center"
      content="Προσθήκη στο ρεπερτόριο"
      trigger={
        <Button
          inverted={props.inverted}
          onClick={() => addSongToMyRepertoireMutation.mutate(props.id)}
          icon="plus"
          positive
          floated={props.floated}
          loading={addSongToMyRepertoireMutation.isLoading}
        />
      }
    />
  ) : (
    <Popup
      inverted={props.inverted}
      position="bottom center"
      content="Αφαίρεση από το ρεπερτόριο"
      trigger={
        <HoveredIconButton
          inverted={props.inverted}
          defaultIcon="check"
          iconOnHover="delete"
          floated={props.floated}
          onClick={() => removeSongFromMyRepertoireMutation.mutate(props.id)}
          isLoading={removeSongFromMyRepertoireMutation.isLoading}
        />
      }
    />
  );
}

export default RepertoireToggleButton;
