import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { Link, useMatches, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Header,
  Loader,
  Segment,
} from "semantic-ui-react";
import { getSong } from "../api/songs";
import AddSongModal from "../components/songs/AddSongModal";
import DeleteSongModal from "../components/songs/DeleteSongModal";
import RepertoireToggleButton from "../components/songs/RepertoireToggleButton";
import { AuthContext } from "../context/AuthContext";
import { ThemeContext } from "../context/ThemeContext";
import { formatDate } from "../utils";

function Song() {
  const { darkTheme } = useContext(ThemeContext);
  const [matched] = useMatches();
  const navigate = useNavigate();
  const { id } = matched.params;
  const { user } = useContext(AuthContext);
  const { data: response } = useQuery(
    ["song", parseInt(id ?? ""), user?.token],
    ({ queryKey }) =>
      getSong(queryKey[1] ? +queryKey[1] : -1, queryKey[2]?.toString()),
    {
      enabled: Boolean(id),
    }
  );

  const roles = {
    composer: response?.persons.find((p) => p.role === "composer"),
    songwriter: response?.persons.find((p) => p.role === "songwriter"),
  };

  return response ? (
    <>
      <Helmet>
        <title>{`${response.title} ${
          response.altTitle ? `(${response.altTitle})` : ""
        } - Παρόλες.gr`}</title>
        <meta
          name="description"
          content={response.lyrics.substring(0, 100) + "..."}
        />
        <meta
          property="og:title"
          content={`${response.title} ${
            response.altTitle ? `(${response.altTitle})` : ""
          } - Παρόλες.gr`}
        />
        <meta property="og:type" content="music.song" />
        {roles.composer ? (
          <meta
            property="music:musician"
            content={`${roles.composer?.person.lastName} ${roles.composer?.person.firstName}`}
          />
        ) : null}
      </Helmet>
      <Segment inverted={darkTheme}>
        <Container text>
          {user ? (
            <RepertoireToggleButton
              isInRepertoire={
                response.users ? response.users.length > 0 : false
              }
              id={response.id}
              floated="right"
              withLabel
            />
          ) : null}
          <Header inverted={darkTheme}>
            {response.title}{" "}
            {response.altTitle ? `(${response.altTitle})` : null}
          </Header>
          <p>
            Συνθέτης:{" "}
            {roles.composer ? (
              <Link to={`/persons/${roles.composer?.person.id}`}>
                {roles.composer?.person.lastName}{" "}
                {roles.composer?.person.firstName}
              </Link>
            ) : (
              "Άγνωστος"
            )}
          </p>
          <Divider />
          <pre style={{ whiteSpace: "pre-wrap" }}>
            <span>{response.lyrics}</span>
          </pre>
        </Container>
      </Segment>
      <Segment inverted={darkTheme}>
        <Header inverted={darkTheme} as="h4">
          Περισσότερα στοιχεία
        </Header>
        <p>
          Στιχουργός:{" "}
          {roles.songwriter ? (
            <Link to={`/persons/${roles.songwriter?.person.id}`}>
              {roles.songwriter?.person.lastName}{" "}
              {roles.songwriter?.person.firstName}
            </Link>
          ) : (
            "Άγνωστος"
          )}
        </p>
        <p>Έτος κυκλοφορίας: {response.year ?? "Άγνωστο"}</p>
        <Header as="h4">Πληροφοριές καταχώρησης</Header>
        <p>
          Τελευταία αλλαγή: {formatDate(new Date(response?.modifiedOn ?? ""))}
        </p>
        <p>
          Δημιουργήθηκε: {formatDate(new Date(response?.createdAt ?? ""))} από{" "}
          {response?.createdBy.username}
        </p>
        {user && user.role !== "user" ? (
          <AddSongModal
            trigger={<Button inverted={darkTheme} icon="edit" content="Επεξεργασία" />}
            mode="EDIT"
            id={response.id}
          />
        ) : null}
        {user && user.role === "admin" ? (
          <DeleteSongModal
            trigger={<Button inverted={darkTheme} icon="trash" content="Διαγραφή" />}
            song={response}
            onDelete={() => navigate("/songs")}
          />
        ) : null}
      </Segment>
    </>
  ) : (
    <Segment>
      <Loader active inline="centered" />
    </Segment>
  );
}

export default Song;
