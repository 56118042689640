import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Header, Icon, Loader, Segment } from "semantic-ui-react";
import { IVerifyEmailOptions, verifyEmail } from "../api/users";

function VerifyEmail() {
  const navigation = useLocation();
  const navigate = useNavigate();
  const query = navigation.search;
  const verifyEmailMutation = useMutation((options: IVerifyEmailOptions) =>
    verifyEmail(options)
  );
  const mutate = verifyEmailMutation.mutate;

  useEffect(() => {
    const params = new URLSearchParams(query);
    const token = params.get("token");
    if (token) {
      mutate({ token });
    }
  }, [mutate, query]);

  return (
    <>
      <Helmet>
        <title>Επιβεβαίωση email - Παρόλες.gr</title>
      </Helmet>
      <Segment textAlign="center">
        {verifyEmailMutation.isLoading ? (
          <Loader active={verifyEmailMutation.isLoading} />
        ) : verifyEmailMutation.isSuccess ? (
          <>
            <Icon size="massive" name="check circle" color="green" />
            <Header>Η διεύθυνση email επιβεβαιώθηκε!</Header>
            <p>Μπορείτε τώρα να συνδεθείτε στην εφαρμογή.</p>
            <Button onClick={() => navigate("/")}>Πίσω στην Αρχική</Button>
          </>
        ) : (
          <>
            <Icon size="massive" name="times circle" color="red" />
            <Header>Ουπς! Συνέβη κάποιο σφάλμα...</Header>
            <p>
              Η διεύθυνση email δεν επιβεβαιώθηκε, προσπαθήστε ξανά ή
              επικοινωνήστε με τον διαχειριστή.
            </p>
            <Button onClick={() => navigate("/")}>Πίσω στην Αρχική</Button>
          </>
        )}
      </Segment>
    </>
  );
}

export default VerifyEmail;
