import { useContext } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { getPersons } from "../../api/persons";
import { AuthContext } from "../../context/AuthContext";
import PaginatedTable from "../PaginatedTable";
import AddPersonModal from "./AddPersonModal";
import PersonRow from "./PersonsRow";

interface IProps {
  role?: "all" | "composer" | "songwriter";
  inverted?: boolean;
}

function PersonsTable(props: IProps) {
  const { role = "all" } = props;
  const { user } = useContext(AuthContext);
  const searchOptions: any = {};
  if (role && role !== "all") {
    searchOptions.role = role;
  }

  return (
    <PaginatedTable
      inverted={props.inverted}
      pageSize={10}
      header={
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Καλλιτέχνης</Table.HeaderCell>
            <Table.HeaderCell>Τραγούδια</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      }
      footer={
        <>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>
            {user && user.role !== "user" ? (
              <AddPersonModal
                mode="CREATE"
                trigger={
                  <Button
                    inverted={props.inverted}
                    floated="right"
                    icon
                    labelPosition="left"
                    primary
                    size="small"
                  >
                    <Icon name="plus" />
                    Νέος καλλιτέχνης
                  </Button>
                }
              />
            ) : null}
          </Table.HeaderCell>
        </>
      }
      row={(s) => (
        <PersonRow
          inverted={props.inverted}
          person={s}
          role={searchOptions.role}
        />
      )}
      queryFunction={getPersons}
      queryKey={["persons"]}
      options={searchOptions}
    />
  );
}

export default PersonsTable;
