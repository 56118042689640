import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Header,
  Search,
  SearchProps,
  Segment,
  SegmentProps,
} from "semantic-ui-react";
import { searchPersons } from "../../api/persons";
import { AuthContext } from "../../context/AuthContext";
import useDebounce from "../../hooks/useDebounce";

interface ISearchResult {
  id: number;
  title: string;
  description?: string;
}

interface IProps {
  textAlign?: SegmentProps["textAlign"];
  fluid?: SearchProps["fluid"];
  inverted?: SegmentProps["inverted"];
}

function rolesSwitch(role: "composer" | "songwriter") {
  switch (role) {
    case "composer":
      return "Συνθέτης";
    case "songwriter":
      return "Στιχουργός";
    default:
      return role;
  }
}

function SearchPerson(props: IProps) {
  const { user } = useContext(AuthContext);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 250);
  const navigate = useNavigate();
  const { data: responsePersons, isFetching } = useQuery(
    ["persons", debouncedQuery, ...(user?.token ? ["user"] : [])],
    () => searchPersons({ query: debouncedQuery, token: user?.token }),
    { enabled: debouncedQuery.length > 0 }
  );

  const [results, setResults] = useState<Array<ISearchResult>>([]);

  useEffect(() => {
    if (!responsePersons) {
      return;
    }
    const res: ISearchResult[] = responsePersons.map((r) => {
      return {
        id: r.id,
        title: `${r.lastName} ${r.firstName}`,
        description: r.roles
          ? r.roles
              .split(",")
              .map(rolesSwitch as any)
              .sort((a, b) => (a === "Στιχουργός" ? -1 : 1))
              .join(", ")
          : "Καμία ιδιότητα",
      };
    });

    setResults(res);
  }, [responsePersons]);
  return (
    <Segment inverted={props.inverted} textAlign={props.textAlign}>
      <Header>Αναζήτηση καλλιτέχνη</Header>
      <p>Γράψε το ονοματεπώνυμο ενός στιχουργού ή ενός συνθέτη.</p>
      <Search
        placeholder="Όνομα καλλιτέχνη"
        noResultsMessage="Δεν βρέθηκαν αποτελέσματα..."
        results={results}
        fluid={props.fluid}
        loading={isFetching || query !== debouncedQuery}
        onSearchChange={(ev, data) => setQuery(data.value ?? "")}
        onResultSelect={(ev, data) => {
          navigate(`/persons/${data.result.id}`);
        }}
      />
    </Segment>
  );
}

export default SearchPerson;
