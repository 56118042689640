import { ReactNode } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Dimmer, Icon, Loader, Menu, Table } from "semantic-ui-react";

type PaginatedQueryOptions<TQueryOptions> = TQueryOptions & { offset: number };

interface IProps<TResult extends { id: number | string }, TQueryOptions> {
  header: ReactNode;
  row: (r: TResult) => ReactNode;
  footer: ReactNode;
  queryKey: string[];
  options: TQueryOptions;
  pageSize: number;
  inverted?: boolean;
  queryFunction: (
    options: PaginatedQueryOptions<TQueryOptions>
  ) => Promise<TResult[]>;
}

function PaginatedTable<TResult extends { id: number | string }, TQueryOptions>(
  props: IProps<TResult, TQueryOptions>
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const offset = parseInt(searchParams.get("offset") ?? "0");
  if (isNaN(offset)) {
    throw new Error("Invalid offset!");
  }
  const nextPage = () => {
    setSearchParams((searchParams) => {
      searchParams.set("offset", (offset + props.pageSize).toString());
      return searchParams;
    });
  };

  const previousPage = () => {
    setSearchParams((searchParams) => {
      searchParams.set("offset", (offset - props.pageSize).toString());
      return searchParams;
    });
  };

  const queryOptions: PaginatedQueryOptions<TQueryOptions> = {
    ...props.options,
    offset,
  };

  const { data } = useQuery<
    TResult[],
    unknown,
    TResult[],
    [...string[], PaginatedQueryOptions<TQueryOptions>]
  >([...props.queryKey, queryOptions], (context) => {
    const last = context.queryKey.at(-1);
    if (!last) {
      throw new Error("not possible!");
    }
    if (typeof last === "string") {
      throw new Error("not possible!");
    }
    return props.queryFunction(last);
  });

  return (
    <Table selectable inverted={props.inverted}>
      <>{props.header}</>
      <Dimmer.Dimmable as={Table.Body} blurring dimmed={!data}>
        <Dimmer active={!data} inverted={!props.inverted}>
          <Loader />
        </Dimmer>
        {data
          ? data.map((d) => (
              <Table.Row inverted={props.inverted} key={d.id}>
                {props.row(d)}
              </Table.Row>
            ))
          : [...Array(props.pageSize)].map(() => (
              <Table.Row>
                <Table.Cell> </Table.Cell>
              </Table.Row>
            ))}
      </Dimmer.Dimmable>
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell>
            <Menu pagination inverted={props.inverted}>
              <Menu.Item
                onClick={previousPage}
                disabled={!(offset && offset > 0)}
                icon
              >
                <Icon name="chevron left" />
              </Menu.Item>
              <Menu.Item
                onClick={nextPage}
                disabled={data ? data?.length < props.pageSize : true}
                as="a"
                icon
              >
                <Icon name="chevron right" />
              </Menu.Item>
            </Menu>
            <span style={{ marginLeft: "10px" }}>
              <b>Σελίδα: {(offset + 10) / 10}</b>
            </span>
          </Table.HeaderCell>
          {props.footer}
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}
export default PaginatedTable;
