import { ReactNode, useRef, useState } from "react";
import { useMutation } from "react-query";
import { Modal, Form, Button, Dimmer, Loader } from "semantic-ui-react";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY } from "../config";
import { useNavigate } from "react-router-dom";
import { forgotPassword, IForgotPasswordOptions } from "../api/user";

interface IProps {
  trigger: ReactNode;
  onClose?: () => void;
}

function ForgotPasswordModal(props: IProps) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const captchaRef = useRef<ReCAPTCHA | null>(null);

  const forgotPasswordMutation = useMutation(
    ({ email, token }: IForgotPasswordOptions) =>
      forgotPassword({ token, email })
  );

  async function sendEmail() {
    if (!recaptchaToken) {
      throw new Error("Impossible!");
    }
    captchaRef.current?.reset();
    await forgotPasswordMutation.mutateAsync({
      email,
      token: recaptchaToken,
    });
    navigate("/forgot_password_success");
    close();
  }

  function validate() {
    return email.length > 4 && recaptchaToken;
  }

  function close() {
    captchaRef.current?.reset();
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  }

  return (
    <Modal
      onClose={close}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
    >
      <Modal.Header>Ξέχασα τον κωδικό μου</Modal.Header>
      <Modal.Content>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <Form>
          <Form.Field required>
            <label>Email</label>
            <input
              placeholder="Email"
              type="email"
              onChange={(ev) => setEmail(ev.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
              ref={captchaRef}
              asyncScriptOnLoad={() => setIsLoading(false)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => close()}>
          Ακύρωση
        </Button>
        <Button
          content="Αποστολή email"
          labelPosition="right"
          icon="checkmark"
          onClick={() => sendEmail()}
          positive
          loading={forgotPasswordMutation.isLoading}
          disabled={!validate()}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ForgotPasswordModal;
