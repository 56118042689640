import axios from "./axios";

export interface IChangePasswordOptions {
  token: string;
  newPassword: string;
  oldPassword: string;
}

export async function changePassword(options: IChangePasswordOptions) {
  const { token, newPassword, oldPassword } = options;
  const res = await axios({
    method: "patch",
    url: `/users/change_password`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      newPassword,
      oldPassword,
    },
  });

  return res.data;
}

export interface IForgotPasswordOptions {
  email: string;
  token: string;
}

export async function forgotPassword(options: IForgotPasswordOptions) {
  const { email, token } = options;
  const res = await axios({
    method: "post",
    url: `/users/forgot_password`,
    data: {
      email,
      token,
    },
  });

  return res.data;
}

export interface IResetPasswordOptions {
  token: string;
  newPassword: string;
}

export async function resetPassword(options: IResetPasswordOptions) {
  const { newPassword, token } = options;
  const res = await axios({
    method: "post",
    url: `/users/reset_password`,
    data: {
      token,
      newPassword,
    },
  });

  return res.data;
}
