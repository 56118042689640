import axios from "./axios";
import { ISong } from "./interfaces";

interface IGetUsersOptions {
  token: string;
  offset: number;
}

export interface IUpdateUserOptions {
  token: string;
  id: number;
  editObject: Partial<Omit<IUser, "id">>;
}

export interface ISendVerificationEmailOptions {
  token: string;
  id: number;
}

export interface IVerifyEmailOptions {
  token: string;
}

export interface IUser {
  id: number;
  username: string;
  email: string;
  role: "admin" | "user" | "editor";
  emailVerified: boolean;
  active: boolean;
  emailToken: boolean;
  createdAt: string;
  modifiedOn: string;
}

export interface IGetRepertoireOfUserOptions {
  token: string;
  userId: number;
  offset?: number;
}

export interface IGetMyRepertoireOptions {
  token: string;
  offset?: number;
}

export async function getRepertoireOfUser({
  token,
  userId,
  offset,
}: IGetRepertoireOfUserOptions): Promise<ISong[]> {
  const res = await axios({
    method: "get",
    url: `/users/${userId}/repertoire`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      offset,
    },
  });

  return res.data;
}

export async function getMyRepertoire({
  token,
  offset = 0,
}: IGetMyRepertoireOptions): Promise<ISong[]> {
  const res = await axios({
    method: "get",
    url: `/users/repertoire`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      offset,
    },
  });

  return res.data;
}

export async function getUsers(options: IGetUsersOptions) {
  const { token, offset } = options;
  const res = await axios({
    method: "get",
    url: `/users`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      offset,
    },
  });

  return res.data as IUser[];
}

export async function updateUser(options: IUpdateUserOptions) {
  const { token, id, editObject } = options;
  const res = await axios({
    method: "patch",
    url: `/users/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: editObject,
  });

  return res.data;
}

export async function verifyEmail(options: IVerifyEmailOptions) {
  const { token } = options;
  const res = await axios({
    method: "post",
    url: `/users/verify_email`,
    data: {
      token,
    },
  });

  return res.data;
}

export async function addToMyRepertoire(id: number, token: string) {
  const res = await axios({
    method: "post",
    url: `/users/repertoire`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      songId: id,
    },
  });

  return res.data;
}

export async function deleteFromMyRepertoire(id: number, token: string) {
  const res = await axios({
    method: "delete",
    url: `/users/repertoire/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
}

export async function sendVerificationEmail(
  options: ISendVerificationEmailOptions
) {
  const { token, id } = options;
  const res = await axios({
    method: "post",
    url: `/users/${id}/send_verification`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
}
