import axios from "./axios";
import { ISong, ISongDto } from "./interfaces";

export async function getSong(id: number, token?: string): Promise<ISong> {
  const res = await axios({
    method: "get",
    url: `/songs/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
}

export async function getSongCount() {
  const res = await axios({
    method: "get",
    url: `/songs/count`,
  });

  return res.data;
}

export async function searchSongs(
  query: string,
  token?: string
): Promise<ISong[]> {
  const res = await axios({
    method: "get",
    url: `/songs/search`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      query,
    },
  });

  return res.data;
}
export interface IGetSongsOptions {
  composer?: number;
  token?: string;
  offset?: number;
  sortBy?: "createdAt";
  artistRole?: "composer" | "songwriter";
}

export async function getSongs({
  composer,
  token,
  offset = 0,
  sortBy,
  artistRole,
}: IGetSongsOptions): Promise<ISong[]> {
  const res = await axios({
    method: "get",
    url: `/songs`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      composer,
      offset,
      sortBy,
      artistRole,
    },
  });

  return res.data;
}

export async function postSong(song: ISongDto, token: string): Promise<ISong> {
  const res = await axios({
    method: "post",
    url: `/songs`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: song,
  });

  return res.data;
}

export async function editSong(
  id: number,
  song: Partial<ISongDto>,
  token: string
) {
  const res = await axios({
    method: "patch",
    url: `/songs/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: song,
  });

  return res.data;
}

export async function deleteSong(id: number, token: string) {
  const res = await axios({
    method: "delete",
    url: `/songs/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
}
