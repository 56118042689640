import { isAxiosError } from "axios";
import { ReactNode, useEffect } from "react";
import { useQueryClient } from "react-query";
import useError from "../hooks/useError";

interface IProps {
  children: ReactNode;
}

function QueryClientDefaults(props: IProps) {
  const queryClient = useQueryClient();
  const { throwError } = useError();

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        onError: throwError,
        retry: (failureCount, error) => {
          if (isAxiosError(error)) {
            if (error.response?.status === 404) {
              return false;
            }
          }
          if (failureCount < 3) {
            return true;
          }

          return false;
        },
      },
    });
  }, [queryClient, throwError]);
  return <>{props.children}</>;
}

export default QueryClientDefaults;
