import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Dimmer, Loader, Modal } from "semantic-ui-react";
import { IPerson, ISongDto } from "../../api/interfaces";
import { editSong, getSong, postSong } from "../../api/songs";
import { AuthContext } from "../../context/AuthContext";
import SongForm from "./SongForm";

interface IProps {
  trigger: React.ReactNode;
  mode: "EDIT" | "CREATE";
  id?: number;
}

function AddSongModal(props: IProps) {
  const [open, setOpen] = useState(false);
  const { data } = useQuery(
    ["song", props.id],
    ({ queryKey }) => getSong(queryKey[1] ? +queryKey[1] : -1),
    {
      onSettled(data, error) {
        if (!data) {
          navigate("/404");
        }
      },
      enabled: Boolean(props.id) && open,
    }
  );
  const [title, setTitle] = useState("");
  const [altTitle, setAltTitle] = useState("");
  const [songComposer, setSongComposer] = useState<Pick<
    IPerson,
    "id" | "firstName" | "lastName"
  > | null>();
  const [composer, setComposer] = useState<number | null>(null);
  const [songSongwriter, setSongSongwriter] = useState<Pick<
    IPerson,
    "id" | "firstName" | "lastName"
  > | null>();
  const [songwriter, setSongwriter] = useState<number | null>(null);
  const [lyrics, setLyrics] = useState("");
  const [year, setYear] = useState("");
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data) {
      const retrievedComposer = data?.persons?.find(
        (p) => p.role === "composer"
      )?.person;
      const retrievedSongwriter = data?.persons?.find(
        (p) => p.role === "songwriter"
      )?.person;
      setTitle(data.title);
      setAltTitle(data.altTitle ?? "");
      setSongComposer(retrievedComposer);
      setComposer(retrievedComposer?.id ?? null);
      setSongSongwriter(retrievedSongwriter);
      setSongwriter(retrievedSongwriter?.id ?? null);
      setYear(data.year ?? "");
      setLyrics(data.lyrics);
    }
  }, [data, songComposer]);

  const postSongMutation = useMutation(
    (song: ISongDto) => {
      if (!user) {
        throw new Error("Not authenticated!");
      }

      return postSong(song, user.token);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("songs");
        toast(`Το τραγούδι "${data.title}" προστέθηκε επιτυχώς στη βιβλιοθήκη!`, {
          type: "success",
        });
      },
    }
  );

  const editSongMutation = useMutation(
    ({ id, song }: { id: number; song: Partial<ISongDto> }) => {
      if (!user) {
        throw new Error("Not authenticated!");
      }

      return editSong(id, song, user.token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("songs");
        queryClient.invalidateQueries(["song", props.id]);
        toast(`Το τραγούδι επεξεργάστηκε επιτυχώς!`, {
          type: "success",
        });
      },
    }
  );

  async function sendDataCreate() {
    const sendObject: ISongDto = {
      title,
      composer,
      songwriter,
      lyrics,
    };

    if (altTitle.length > 0) {
      sendObject.altTitle = altTitle;
    }
    if (year.length > 0) {
      sendObject.year = year;
    }
    const song = await postSongMutation.mutateAsync(sendObject);
    setOpen(false);
    navigate(`/songs/${song.id}`);
  }

  async function sendDataEdit() {
    if (!props.id || !data) {
      return;
    }
    const editObject: Partial<ISongDto> = {};
    if (title.length > 0 && data.title !== title) {
      editObject.title = title;
    }
    if (songComposer?.id !== composer) {
      editObject.composer = composer;
    }
    if (songSongwriter?.id !== songwriter) {
      editObject.songwriter = songwriter;
    }
    if (lyrics.length > 0 && data.lyrics !== lyrics) {
      editObject.lyrics = lyrics;
    }
    if (data.altTitle !== altTitle) {
      editObject.altTitle = altTitle;
    }
    if (data.year !== year) {
      editObject.year = year;
    }
    await editSongMutation.mutateAsync({ id: props.id, song: editObject });
    setOpen(false);
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
    >
      <Modal.Header>
        {props.mode === "CREATE"
          ? "Προσθήκη Κομματιού"
          : "Επεξεργασία Κομματιού"}
      </Modal.Header>
      <Modal.Content>
        <Dimmer active={!data && props.mode === "EDIT"}>
          <Loader active={!data && props.mode === "EDIT"} inline="centered" />
        </Dimmer>
        <SongForm
          title={title}
          setTitle={setTitle}
          altTitle={altTitle}
          setAltTitle={setAltTitle}
          year={year}
          setYear={setYear}
          composer={composer}
          setComposer={setComposer}
          songwriter={songwriter}
          setSongwriter={setSongwriter}
          lyrics={lyrics}
          setLyrics={setLyrics}
          initialComposers={[
            ...(songComposer ? [songComposer] : []),
            ...(songSongwriter ? [songSongwriter] : []),
          ]}
          isEdit={props.mode === "EDIT"}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Ακύρωση
        </Button>
        <Button
          content={props.mode === "CREATE" ? "Προσθήκη" : "Επεξεργασία"}
          labelPosition="right"
          icon="checkmark"
          onClick={() =>
            props.mode === "CREATE" ? sendDataCreate() : sendDataEdit()
          }
          isLoading={editSongMutation.isLoading || postSongMutation.isLoading}
          positive
          disabled={
            (props.mode === "CREATE"
              ? title.length < 1 || lyrics.length < 1
              : title.length < 1 && lyrics.length < 1) &&
            (editSongMutation.isLoading || postSongMutation.isLoading)
          }
        />
      </Modal.Actions>
    </Modal>
  );
}

export default AddSongModal;
