import { useContext, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container, Icon, Menu, Sidebar } from "semantic-ui-react";
import AppHeader from "../components/AppHeader";
import { ThemeContext } from "../context/ThemeContext";

function MobileLayout() {
  const { darkTheme } = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);
  const navigation = useLocation();
  const toggleSidebar = () => {
    setVisible((visible) => !visible);
  };

  return (
    <div className="App">
      <Sidebar.Pushable>
        <Sidebar
          inverted={darkTheme}
          as={Menu}
          animation="overlay"
          icon="labeled"
          onHide={() => setVisible(false)}
          vertical
          visible={visible}
          width="thin"
        >
          <Menu.Item
            onClick={() => setVisible(false)}
            active={navigation.pathname === "/"}
            link
            as={Link}
            to="/"
          >
            <Icon name="home" />
            Αρχική
          </Menu.Item>
          <Menu.Item
            active={navigation.pathname === "/songs"}
            link
            onClick={() => setVisible(false)}
            as={Link}
            to="/songs"
          >
            <Icon name="music" />
            Τραγούδια
          </Menu.Item>
          <Menu.Item
            onClick={() => setVisible(false)}
            active={navigation.pathname === "/persons"}
            link
            as={Link}
            to="/persons"
          >
            <Icon name="user" />
            Καλλιτέχνες
          </Menu.Item>
          <Menu.Item
            onClick={() => setVisible(false)}
            active={navigation.pathname === "/about"}
            link
            as={Link}
            to="/about"
          >
            <Icon name="info" />
            Πληροφορίες
          </Menu.Item>
        </Sidebar>
        <Sidebar.Pusher>
          <AppHeader toggleSidebar={toggleSidebar} withSidebar />
          <Container
            style={{
              height: "fit-content",
              minHeight: "calc(100vh - 101px)",
              marginBottom: "10px",
            }}
          >
            <Outlet />
            <div style={{ marginTop: "20px" }}></div>
            <ToastContainer />
          </Container>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
}

export default MobileLayout;
