import { ReactNode, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { IPerson } from "../../api/interfaces";
import { deletePerson } from "../../api/persons";
import { AuthContext } from "../../context/AuthContext";

interface IProps {
  trigger: ReactNode;
  person: IPerson;
}

function DeletePersonModal(props: IProps) {
  const [open, setOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const deletePersonMutation = useMutation(
    (id: number) => {
      if (!user) {
        throw new Error("Not authenticated!");
      }

      return deletePerson(id, user.token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("persons");
        toast(
          `Ο καλλιτέχνης ${props.person.lastName} ${props.person.firstName} διαγράφηκε επιτυχώς από τη βιβλιοθήκη.`,
          { type: "success" }
        );
      },
    }
  );

  const yes = async () => {
    await deletePersonMutation.mutateAsync(props.person.id);
    setOpen(false);
  };

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={props.trigger}
    >
      <Header icon>
        <Icon name="delete" />
        Διαγραφή καλλιτέχνη
      </Header>
      <Modal.Content>
        <p>
          Είσαι σίγουρος ότι θέλεις να διαγράψεις τον καλλιτέχνη "
          <b>
            {props.person.lastName} {props.person.firstName}
          </b>
          ";
        </p>
        <p>Αν το διαγράψεις δεν θα μπορεί να ανακτηθεί.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={yes}>
          <Icon name="remove" /> Ναι
        </Button>
        <Button color="green" inverted onClick={() => setOpen(false)}>
          <Icon name="checkmark" /> Όχι
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeletePersonModal;
