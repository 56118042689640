import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Header, Form, Message } from "semantic-ui-react";
import { resetPassword } from "../api/user";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigation = useLocation();
  const query = navigation.search;
  const params = new URLSearchParams(query);
  const token = params.get("token");

  const navigate = useNavigate();

  const validatePassword = () => {
    if (confirmPassword !== newPassword) {
      return false;
    }

    return newPassword.length >= 8;
  };

  const resetPasswordMutation = useMutation(
    () => {
      if (!token) {
        throw new Error("Not possible without token!");
      }
      return resetPassword({ token, newPassword });
    },
    {
      onSuccess: () => {
        toast(`Ο κωδικός άλλαξε επιτυχώς!`, {
          type: "success",
        });
      },
    }
  );

  const changePasswordAction = async () => {
    await resetPasswordMutation.mutateAsync();
    setNewPassword("");
    setConfirmPassword("");
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>Επαναφορά κωδικού - Παρόλες.gr</title>
      </Helmet>
      <Header>Επαναφορά κωδικού</Header>
      <Form success={resetPasswordMutation.isSuccess}>
        <Form.Group widths="equal">
          <Form.Field fluid>
            <label>Νέος κωδικός</label>
            <input
              placeholder="Νέος κωδικός"
              type="password"
              value={newPassword}
              disabled={resetPasswordMutation.isLoading}
              onChange={(ev) => setNewPassword(ev.target.value)}
            />
          </Form.Field>
          <Form.Field fluid>
            <label>Επιβεβαίωση κωδικού</label>
            <input
              placeholder="Επιβεβαίωση κωδικού"
              type="password"
              value={confirmPassword}
              disabled={resetPasswordMutation.isLoading}
              onChange={(ev) => setConfirmPassword(ev.target.value)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Button
          disabled={!validatePassword()}
          onClick={changePasswordAction}
          loading={resetPasswordMutation.isLoading}
        >
          Αλλαγή κωδικού
        </Form.Button>
        <Message success header="Ο κωδικός άλλαξε επιτυχώς!" />
      </Form>
    </>
  );
}

export default ResetPassword;
