import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Icon, Table } from "semantic-ui-react";
import { IUser, sendVerificationEmail } from "../../api/users";
import { AuthContext } from "../../context/AuthContext";
import { formatDate } from "../../utils";
import EditUserModal from "./EditUserModal";

interface IProps {
  user: IUser;
}

const roleLabels: { [key in IUser["role"]]: string } = {
  admin: "Διαχειριστής",
  user: "Χρήστης",
  editor: "Συντάκτης",
};

function UserRow(props: IProps) {
  const { user } = props;
  const { user: currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const sendVerificationEmailMutation = useMutation(
    ({ id }: { id: number }) => {
      if (!currentUser) {
        throw new Error("Not authenticated!");
      }
      return sendVerificationEmail({ id, token: currentUser.token });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        toast(
          `Το email επιβεβαίωσης στάλθηκε στον χρήστη "${props.user.username}" στη διεύθυνση "${props.user.email}".`,
          {
            type: "success",
          }
        );
      },
    }
  );
  return (
    <>
      <Table.Cell>{user.id}</Table.Cell>
      <Table.Cell>{user.username}</Table.Cell>
      <Table.Cell>
        {user.email}{" "}
        <Icon
          name={
            user.emailVerified
              ? "check circle"
              : !user.emailToken
              ? "times circle"
              : "clock"
          }
          color={
            user.emailVerified ? "green" : !user.emailToken ? "red" : "grey"
          }
        />
      </Table.Cell>
      <Table.Cell>{roleLabels[user.role]}</Table.Cell>
      <Table.Cell>
        <Icon
          name={user.active ? "check circle" : "times circle"}
          color={user.active ? "green" : "red"}
        />
      </Table.Cell>
      <Table.Cell>{formatDate(new Date(user.createdAt))}</Table.Cell>
      <Table.Cell>{formatDate(new Date(user.modifiedOn))}</Table.Cell>
      <Table.Cell>
        <Button.Group icon>
          <Button
            icon="list"
            onClick={() => navigate(`/repertoire/${user.id}`)}
          />
          <EditUserModal trigger={<Button icon="edit" />} user={user} />
          {!user.emailVerified ? (
            <Button
              icon="send"
              onClick={() =>
                sendVerificationEmailMutation.mutate({
                  id: user.id,
                })
              }
              loading={
                sendVerificationEmailMutation.variables?.id === user.id &&
                sendVerificationEmailMutation.isLoading
              }
            />
          ) : null}
        </Button.Group>
      </Table.Cell>
    </>
  );
}

export default UserRow;
