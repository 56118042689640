import { createContext } from "react";
import { Theme } from "../hooks/useTheme";

export interface IThemeContext {
  theme: Theme;
  darkTheme: boolean;
  lightTheme: boolean;
  userTheme: Theme | "system";
  setUserTheme: (theme: Theme | "system") => void;
}

export const ThemeContext = createContext<IThemeContext>({
  theme: "light",
  darkTheme: false,
  lightTheme: true,
  setUserTheme: () => null,
  userTheme: "light",
});
