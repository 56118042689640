import { useContext, useState } from "react";
// import { useMediaQuery } from "react-responsive";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  Button,
  Menu,
  Container,
  Icon,
  Dropdown,
  Popup,
  Image,
} from "semantic-ui-react";
import { AuthContext } from "../../context/AuthContext";
import { ThemeContext } from "../../context/ThemeContext";
import LoginModal from "../LoginModal";
import RegisterModal from "../RegisterModal";

interface IProps {
  toggleSidebar?: () => void;
  withSidebar?: boolean;
}

// const AppHeaderMenu = styled(Menu)`
//   @media (prefers-color-scheme: dark) {
//     background-color: rgb(17, 17, 17);
//   }

//   .item {
//     color: white;
//   }
// `;

function AppHeader({ toggleSidebar = () => null, withSidebar }: IProps) {
  const { darkTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const navigation = useLocation();
  const { user, logout } = useContext(AuthContext);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);

  return (
    <Menu
      size="large"
      style={{ minHeight: "73px" }}
      borderless
      inverted={darkTheme}
    >
      <Container>
        <Menu.Item
          onClick={withSidebar ? () => toggleSidebar() : () => navigate("/")}
        >
          <Image src="/logo.png" alt="Paroles.gr Logo" size="mini"></Image>
          <span style={{ marginLeft: "10px" }}>
            <h3>Παρόλες</h3>
          </span>
        </Menu.Item>
        {withSidebar ? null : (
          <>
            <Menu.Item
              active={navigation.pathname === "/songs"}
              link
              as={Link}
              to="/songs"
            >
              Τραγούδια
            </Menu.Item>
            <Menu.Item
              active={navigation.pathname === "/persons"}
              link
              as={Link}
              to="/persons"
            >
              Καλλιτέχνες
            </Menu.Item>
          </>
        )}
        <Menu.Menu position="right">
          {!withSidebar ? (
            <Menu.Item
              active={navigation.pathname === "/about"}
              link
              as={Link}
              to="/about"
            >
              Πληροφορίες
            </Menu.Item>
          ) : null}
          {!user ? (
            <Menu.Item>
              <LoginModal
                trigger={
                  <Button
                    primary
                    content="Σύνδεση"
                    icon="sign-in"
                    labelPosition="left"
                  />
                }
                registerModalOpen={() => setRegisterModalOpen(true)}
              />
              <RegisterModal
                open={registerModalOpen}
                setOpen={setRegisterModalOpen}
              />
            </Menu.Item>
          ) : (
            <>
              <Popup
                inverted={darkTheme}
                content="Το ρεπερτόριό μου"
                position="bottom center"
                trigger={
                  <Menu.Item
                    active={navigation.pathname === "/repertoire"}
                    link
                    as={Link}
                    icon
                    to="/repertoire"
                  >
                    <Icon name="list" size="large" />
                  </Menu.Item>
                }
              />
              {user.role === "admin" ? (
                <Popup
                  inverted={darkTheme}
                  content="Διαχείριση"
                  trigger={
                    <Menu.Item
                      active={navigation.pathname === "/users"}
                      link
                      as={Link}
                      icon
                      to="/users"
                    >
                      <Icon name="wrench" size="large" />
                    </Menu.Item>
                  }
                />
              ) : null}
              <Dropdown
                item
                floating
                className="icon"
                icon={<Icon name="user circle" size="big" />}
              >
                <Dropdown.Menu>
                  <Dropdown.Header>{user.username}</Dropdown.Header>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => navigate("/settings")}>
                    <Icon name="settings" />
                    Ρυθμίσεις
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => logout()}>
                    <Icon name="sign-out" />
                    Αποσύνδεση
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </Menu.Menu>
      </Container>
    </Menu>
  );
}

export default AppHeader;
