import { createContext } from "react";

export interface IUserData {
  token: string;
  username: string;
  role: "admin" | "editor" | "user";
}

interface IAuthContext {
  user: IUserData | null;
  login: (data: IUserData) => void;
  logout: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  login: (data: IUserData) => null,
  logout: () => null,
});
