import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Button, Modal } from "semantic-ui-react";
import { IPerson } from "../../api/interfaces";
import { editPerson, IPersonDto, postPerson } from "../../api/persons";
import { AuthContext } from "../../context/AuthContext";
import PersonForm from "./PersonForm";

interface IProps {
  trigger: React.ReactNode;
  mode: "EDIT" | "CREATE";
  person?: Partial<IPerson> & { id: number };
}

function AddPersonModal(props: IProps) {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState(props.person?.firstName ?? "");
  const [lastName, setLastName] = useState(props.person?.lastName ?? "");
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const postPersonMutation = useMutation(
    (person: IPersonDto) => {
      if (!user) {
        throw new Error("Not authenticated!");
      }
      return postPerson(person, user.token);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("persons");
        toast(
          `Ο καλλιτέχνης "${data.lastName} ${data.firstName}" προστέθηκε επιτυχώς στη βιβλιοθήκη!`,
          { type: "success" }
        );
      },
    }
  );
  const editPersonMutation = useMutation(
    ({ id, person }: { id: number; person: Partial<IPersonDto> }) => {
      if (!user) {
        throw new Error("Not authenticated!");
      }
      return editPerson(id, person, user.token);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("persons");
        queryClient.invalidateQueries("person");
        toast(`Ο καλλιτέχνης επεξεργάστηκε επιτυχώς!`, { type: "success" });
      },
    }
  );

  async function sendDataCreate() {
    await postPersonMutation.mutateAsync({ firstName, lastName });
    close();
  }

  async function sendDataEdit() {
    if (!props.person) {
      return;
    }
    const editObject: Partial<IPerson> = {};
    if (firstName.length > 0 && props.person.firstName !== firstName) {
      editObject.firstName = firstName;
    }
    if (lastName.length > 0 && props.person.lastName !== lastName) {
      editObject.lastName = lastName;
    }
    await editPersonMutation.mutateAsync({
      id: props.person.id,
      person: editObject,
    });
    close();
  }

  function close() {
    setFirstName("");
    setLastName("");
    setOpen(false);
  }

  return (
    <Modal
      onClose={close}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
    >
      <Modal.Header>
        {props.mode === "CREATE"
          ? "Προσθήκη καλλιτέχνη"
          : "Επεξεργασία καλλιτέχνη"}
      </Modal.Header>
      <Modal.Content>
        <PersonForm
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={close}>
          Ακύρωση
        </Button>
        <Button
          content={props.mode === "CREATE" ? "Προσθήκη" : "Επεξεργασία"}
          labelPosition="right"
          icon="checkmark"
          onClick={() =>
            props.mode === "CREATE" ? sendDataCreate() : sendDataEdit()
          }
          loading={editPersonMutation.isLoading || postPersonMutation.isLoading}
          positive
          disabled={
            props.mode === "CREATE"
              ? firstName.length < 1 || lastName.length < 1
              : firstName.length < 1 &&
                lastName.length < 1 &&
                (editPersonMutation.isLoading || postPersonMutation.isLoading)
          }
        />
      </Modal.Actions>
    </Modal>
  );
}

export default AddPersonModal;
