import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Button, Card, Loader } from "semantic-ui-react";
import { getPersons } from "../api/persons";

interface IProps {
  role: "composer" | "songwriter";
  inverted?: boolean;
}

function ArtistShowcase(props: IProps) {
  const { data: responseComposers } = useQuery(["persons", props.role], () =>
    getPersons({ role: props.role })
  );
  return !responseComposers ? (
    <Loader inverted={props.inverted} active inline="centered" />
  ) : (
    <>
      <Card.Group
        style={{ marginTop: "10px" }}
        textAlign="center"
        stackable
        itemsPerRow={5}
      >
        {responseComposers.map((p) => (
          <Card
            key={p.id}
            raised
            as={Link}
            to={`/persons/${p.id}?role=${props.role}`}
          >
            <Card.Content>
              <Card.Header>
                {p.lastName} {p.firstName}
              </Card.Header>
              <Card.Description>Τραγούδια: {p.songCount}</Card.Description>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
      <Button
        inverted={props.inverted}
        basic
        fluid
        as={Link}
        to={`/persons?role=${props.role}&offset=10`}
        style={{ marginTop: "20px" }}
      >
        {`Περισσότεροι ${
          props.role === "composer" ? "συνθέτες" : "στιχουργοί"
        }...`}
      </Button>
    </>
  );
}

export default ArtistShowcase;
