import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { ThemeContext } from "../context/ThemeContext";

function ForgotPasswordEmailSent() {
  const navigate = useNavigate();
  const { darkTheme } = useContext(ThemeContext);
  return (
    <>
      <Helmet>
        <title>Επαναφορά κωδικού - Παρόλες.gr</title>
      </Helmet>
      <Segment inverted={darkTheme} textAlign="center">
        <Icon inverted={darkTheme} size="massive" name="mail" color="grey" />
        <Header>Επαναφορά κωδικού...</Header>
        <p>
          Αν το email που πληκτρολόγησες είναι σωστό, τότε θα σου στείλουμε
          email με έναν σύνδεσμο για να επαναφέρεις τον κωδικό σου.
        </p>
        <Button inverted={darkTheme} onClick={() => navigate("/")}>
          Πίσω στην Αρχική
        </Button>
      </Segment>
    </>
  );
}

export default ForgotPasswordEmailSent;
