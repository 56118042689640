import { useContext } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { IPerson } from "../../api/interfaces";
import { AuthContext } from "../../context/AuthContext";
import LinkableTableCell from "../LinkableTableCell";
import AddPersonModal from "./AddPersonModal";
import DeletePersonModal from "./DeletePersonModal";

interface IProps {
  person: IPerson;
  role?: "composer" | "songwriter";
  inverted?: boolean;
}

function rolesSwitch(role: "composer" | "songwriter") {
  switch (role) {
    case "composer":
      return "Συνθέτης";
    case "songwriter":
      return "Στιχουργός";
    default:
      return role;
  }
}

function PersonRow(props: IProps) {
  const { user } = useContext(AuthContext);

  const link = `/persons/${props.person.id}${
    props.role ? `?role=${props.role}` : ""
  }`;

  const roles = props.person.roles?.split(",");

  return (
    <>
      <LinkableTableCell inverted={props.inverted} to={link}>
        <div>
          <b>
            {props.person.lastName} {props.person.firstName}
          </b>
        </div>
        <div>
          {roles
            ? roles
                .map(rolesSwitch as any)
                .sort((a, b) => (a === "Στιχουργός" ? -1 : 1))
                .join(", ")
            : "Καμία ιδιότητα"}
        </div>
      </LinkableTableCell>
      <LinkableTableCell inverted={props.inverted} to={link}>
        {props.person.songCount}
      </LinkableTableCell>
      <Table.Cell textAlign="right">
        {user && user.role === "admin" ? (
          <Button.Group icon>
            <AddPersonModal
              trigger={
                <Button inverted={props.inverted}>
                  <Icon name="edit" />
                </Button>
              }
              person={props.person}
              mode="EDIT"
            />
            <DeletePersonModal
              trigger={
                <Button inverted={props.inverted}>
                  <Icon name="trash" />
                </Button>
              }
              person={props.person}
            />
          </Button.Group>
        ) : null}
      </Table.Cell>
    </>
  );
}

export default PersonRow;
