import { useRef, useState } from "react";
import { useMutation } from "react-query";
import { Modal, Form, Button, Dimmer, Loader } from "semantic-ui-react";
import { postRegister } from "../api/login";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY } from "../config";
import { useNavigate } from "react-router-dom";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function RegisterModal(props: IProps) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const captchaRef = useRef<ReCAPTCHA | null>(null);

  const registerMutation = useMutation(
    ({
      email,
      password,
      username,
      recaptchaToken,
    }: {
      email: string;
      password: string;
      username: string;
      recaptchaToken: string;
    }) => postRegister(email, password, username, recaptchaToken)
  );

  async function register() {
    if (!recaptchaToken) {
      throw new Error("Impossible!");
    }
    captchaRef.current?.reset();
    await registerMutation.mutateAsync({
      email,
      password,
      username,
      recaptchaToken,
    });
    navigate("/registration_success");
    close();
  }

  function validate() {
    if (confirmPassword !== password) {
      return false;
    }
    return (
      email.length > 4 &&
      password.length > 0 &&
      username.length >= 3 &&
      recaptchaToken
    );
  }

  function close() {
    captchaRef.current?.reset();
    props.setOpen(false);
  }

  return (
    <Modal
      onClose={close}
      onOpen={() => {
        props.setOpen(true);
      }}
      open={props.open}
    >
      <Modal.Header>Εγγραφή</Modal.Header>
      <Modal.Content>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <Form>
          <Form.Field required>
            <label>Όνομα Χρήστη</label>
            <input
              placeholder="Όνομα χρήστη"
              onChange={(ev) => setUsername(ev.target.value)}
            />
          </Form.Field>
          <Form.Field required>
            <label>Email</label>
            <input
              placeholder="Email"
              type="email"
              onChange={(ev) => setEmail(ev.target.value)}
            />
          </Form.Field>
          <Form.Field required>
            <label>Κωδικός</label>
            <input
              placeholder="Κωδικός"
              type="password"
              onChange={(ev) => setPassword(ev.target.value)}
            />
          </Form.Field>
          <Form.Field required>
            <label>Επιβεβαίωση κωδικού</label>
            <input
              placeholder="Επιβεβαίωση κωδικού"
              type="password"
              onChange={(ev) => setConfirmPassword(ev.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY}
              onChange={setRecaptchaToken}
              ref={captchaRef}
              asyncScriptOnLoad={() => setIsLoading(false)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => props.setOpen(false)}>
          Ακύρωση
        </Button>
        <Button
          content="Εγγραφή"
          labelPosition="right"
          icon="checkmark"
          onClick={() => register()}
          positive
          loading={registerMutation.isLoading}
          disabled={!validate()}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default RegisterModal;
