import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import { LoginError, postLogin } from "../api/login";
import { AuthContext } from "../context/AuthContext";
import ForgotPasswordModal from "./ForgotPasswordModal";

interface IProps {
  trigger: ReactNode;
  registerModalOpen: () => void;
}

function LoginModal(props: IProps) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<LoginError | null>();
  const { login: loginContext } = useContext(AuthContext);
  const validate = email.length > 4 && password.length > 0;
  const queryClient = useQueryClient();

  const loginMutation = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      postLogin(email, password),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  const login = useCallback(async () => {
    try {
      const res = await loginMutation.mutateAsync({ email, password });
      if (res === LoginError.UNAUTHORIZED) {
        setError(LoginError.UNAUTHORIZED);
        return;
      }
      loginContext({
        token: res.access_token,
        username: res.username,
        role: res.role,
      });
      closeModal();
    } catch (err) {
      setError(LoginError.UNAUTHORIZED);
      return;
    }
  }, [email, password, loginContext, loginMutation]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        if (validate) {
          login();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [login, validate]);

  function closeModal() {
    setEmail("");
    setPassword("");
    setError(null);
    setOpen(false);
  }

  return (
    <Modal
      onClose={closeModal}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
    >
      <Modal.Header>Σύνδεση</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Email</label>
            <input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(ev) => setEmail(ev.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Κωδικός</label>
            <input
              placeholder="Κωδικός"
              type="password"
              value={password}
              onChange={(ev) => setPassword(ev.target.value)}
            />
          </Form.Field>
        </Form>
        {error ? (
          <Message
            error
            header="Λάθος στοιχεία"
            content="Μάλλον έβαλες λάθος το email ή τον κωδικό σου"
          />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button
          floated="left"
          onClick={() => {
            closeModal();
            props.registerModalOpen();
          }}
        >
          Εγγραφή
        </Button>
        <ForgotPasswordModal
          trigger={<Button basic>Ξέχασες τον κωδικό σου;</Button>}
          onClose={closeModal}
        />
        <Button color="black" onClick={closeModal}>
          Ακύρωση
        </Button>
        <Button
          content="Σύνδεση"
          labelPosition="right"
          icon="checkmark"
          onClick={() => login()}
          positive
          loading={loginMutation.isLoading}
          disabled={!validate}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default LoginModal;
