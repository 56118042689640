import { Helmet } from "react-helmet-async";
import { Header } from "semantic-ui-react";

function NotFound() {
  return (
    <>
      <Helmet>
        <title>404 - Παρόλες.gr</title>
        <meta
          name="description"
          content="Αναζήτησε στίχους για τα αγαπημένα σου ελληνικά τραγούδια εύκολα και γρήγορα χωρίς διαφημίσεις!"
        />
        <meta property="og:title" content="Αρχική σελίδα - Παρόλες.gr" />
      </Helmet>
      <Header size="huge">Ώχ! Η σελίδα αυτή δεν υπάρχει!</Header>
    </>
  );
}

export default NotFound;
