import { ReactNode, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { ISong } from "../../api/interfaces";
import { deleteSong } from "../../api/songs";
import { AuthContext } from "../../context/AuthContext";

interface IProps {
  trigger: ReactNode;
  song: ISong;
  onDelete?: () => void;
}

function DeleteSongModal(props: IProps) {
  const [open, setOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const deleteSongMutation = useMutation(
    (id: number) => {
      if (!user) {
        throw new Error("Not authenticated!");
      }
      return deleteSong(id, user.token);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("songs");
        queryClient.invalidateQueries(["song", props.song.id]);
        toast(`Το τραγούδι "${props.song.title}" διαγράφηκε επιτυχώς από τη βιβλιοθήκη.`, {
          type: "success",
        });
      },
    }
  );

  const yes = async () => {
    await deleteSongMutation.mutateAsync(props.song.id);
    setOpen(false);
    if (props.onDelete) {
      props.onDelete();
    }
  };

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={props.trigger}
    >
      <Header icon>
        <Icon name="delete" />
        Διαγραφή τραγουδιού
      </Header>
      <Modal.Content>
        <p>
          Είσαι σίγουρος ότι θέλεις να διαγράψεις το τραγούδι "
          <b>
            {props.song.title}
            {props.song.altTitle ? ` (${props.song.altTitle})` : null}
          </b>
          ";
        </p>
        <p>
          Αν το διαγράψεις, δεν θα μπορεί να ανακτηθεί και θα διαγραφεί και από
          τα ρεπερτόρια όλων των χρηστών.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={yes}>
          <Icon name="remove" /> Ναι
        </Button>
        <Button color="green" inverted onClick={() => setOpen(false)}>
          <Icon name="checkmark" /> Όχι
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeleteSongModal;
