import axios from "./axios";
import { IPerson } from "./interfaces";

export type IPersonDto = Omit<
  IPerson,
  "id" | "modifiedOn" | "createdAt" | "createdBy" | "songCount"
>;

export async function getPerson(id: number, token?: string): Promise<IPerson> {
  const res = await axios({
    method: "get",
    url: `/persons/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
}

export async function getPersonCount() {
  const res = await axios({
    method: "get",
    url: `/persons/count`,
  });

  return res.data;
}

export async function searchPersons(params: {
  query: string;
  token?: string;
}): Promise<IPerson[]> {
  const { query, token } = params;
  const res = await axios({
    method: "get",
    url: `/persons/search`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      query,
    },
  });

  return res.data;
}

export async function getPersons(
  params: {
    offset?: number;
    token?: string;
    role?: "composer" | "songwriter";
  } = {}
): Promise<IPerson[]> {
  const { token, offset, role } = params;
  const res = await axios({
    method: "get",
    url: `/persons`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      offset,
      role,
    },
  });

  return res.data;
}

export async function postPerson(
  person: IPersonDto,
  token: string
): Promise<IPerson> {
  const res = await axios({
    method: "post",
    url: `/persons`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: person,
  });

  return res.data;
}

export async function editPerson(
  id: number,
  person: Partial<IPersonDto>,
  token: string
) {
  const res = await axios({
    method: "patch",
    url: `/persons/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: person,
  });

  return res.data;
}

export async function deletePerson(id: number, token: string) {
  const res = await axios({
    method: "delete",
    url: `/persons/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
}
