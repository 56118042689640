import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container } from "semantic-ui-react";
import AppHeader from "../components/AppHeader";

function DesktopLayout() {
  return (
    <div className="App">
      <AppHeader />
      <Container
        style={{
          height: "fit-content",
          minHeight: "calc(100vh - 101px)",
        }}
      >
        <Outlet />
        <div style={{ marginTop: "20px" }}></div>
        <ToastContainer />
      </Container>
    </div>
  );
}

export default DesktopLayout;
