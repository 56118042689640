import { useContext } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { getSongs } from "../../api/songs";
import { AuthContext } from "../../context/AuthContext";
import PaginatedTable from "../PaginatedTable";
import AddSongModal from "./AddSongModal";
import SongRow from "./SongRow";

interface IProps {
  inverted?: boolean;
  artist?: number;
  showCreatedAt?: boolean;
  showAddButton?: boolean;
  artistRole?: "composer" | "songwriter" | "all";
}

function SongTable(props: IProps) {
  const { user } = useContext(AuthContext);
  const { artistRole = "all" } = props;
  const searchOptions: any = {};
  if (artistRole && artistRole !== "all") {
    searchOptions.artistRole = artistRole;
  }

  return (
    <PaginatedTable
      inverted={props.inverted}
      pageSize={10}
      header={
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Τραγούδι</Table.HeaderCell>
            {props.artist ? (
              <Table.HeaderCell>Ιδιότητα</Table.HeaderCell>
            ) : null}
            {props.showCreatedAt ? (
              <Table.HeaderCell>Προστέθηκε</Table.HeaderCell>
            ) : null}
            <Table.HeaderCell width={3}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      }
      queryFunction={getSongs}
      queryKey={["songs"]}
      options={{
        ...searchOptions,
        composer: props.artist,
        token: user?.token,
        sortBy: props.showCreatedAt ? ("createdAt" as "createdAt") : undefined,
      }}
      row={(s) => (
        <SongRow
          inverted={props.inverted}
          song={s}
          artist={props.artist}
          showCreatedAt={props.showCreatedAt}
        />
      )}
      footer={
        <>
          {props.artist ? <Table.HeaderCell></Table.HeaderCell> : null}
          {props.showCreatedAt ? <Table.HeaderCell></Table.HeaderCell> : null}
          <Table.HeaderCell>
            {user && user.role !== "user" && props.showAddButton ? (
              <AddSongModal
                mode="CREATE"
                trigger={
                  <Button
                    inverted={props.inverted}
                    floated="right"
                    icon
                    labelPosition="left"
                    primary
                    size="small"
                  >
                    <Icon name="plus" />
                    Νέο τραγούδι
                  </Button>
                }
              />
            ) : null}
          </Table.HeaderCell>
        </>
      }
    />
  );
}

export default SongTable;
