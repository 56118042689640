import { useContext, useEffect, useState } from "react";
import { Button, Table } from "semantic-ui-react";
import { ISong } from "../../api/interfaces";
import { AuthContext } from "../../context/AuthContext";
import { formatDate } from "../../utils";
import LinkableTableCell from "../LinkableTableCell";
import AddSongModal from "./AddSongModal";
import DeleteSongModal from "./DeleteSongModal";
import RepertoireToggleButton from "./RepertoireToggleButton";

interface IProps {
  song: ISong;
  hideMutations?: boolean;
  artist?: number;
  showCreatedAt?: boolean;
  inverted?: boolean;
}

function computeRoles(artist: number, song: ISong) {
  return song.persons
    .filter((p) => p.person.id === artist)
    .map((p) => rolesSwitch(p.role))
    .sort((a, b) => (a === "Στιχουργός" ? -1 : 1))
    .join("/");
}

function rolesSwitch(role: "composer" | "songwriter") {
  switch (role) {
    case "composer":
      return "Συνθέτης";
    case "songwriter":
      return "Στιχουργός";
    default:
      return role;
  }
}

function SongRow(props: IProps) {
  const { user } = useContext(AuthContext);
  const [roles, setRoles] = useState("");

  useEffect(() => {
    if (props.artist) {
      setRoles(computeRoles(props.artist, props.song));
    }
  }, [props.artist, props.song]);

  const songTitle = `${props.song.title} ${
    props.song.altTitle ? `(${props.song.altTitle})` : ""
  }`;
  const composer = props.song.persons.find(
    (p) => p.role === "composer"
  )?.person;

  return (
    <>
      <LinkableTableCell
        inverted={props.inverted}
        to={`/songs/${props.song.id}`}
      >
        <div>
          <b>{songTitle}</b>
        </div>
        <div>
          {(composer
            ? `${composer.lastName} ${composer.firstName}`
            : "Άγνωστος") + (props.song.year ? ` - ${props.song.year}` : "")}
        </div>
      </LinkableTableCell>
      {props.artist ? (
        <LinkableTableCell
          inverted={props.inverted}
          to={`/songs/${props.song.id}`}
        >
          {roles}
        </LinkableTableCell>
      ) : null}
      {props.showCreatedAt ? (
        <LinkableTableCell
          inverted={props.inverted}
          to={`/songs/${props.song.id}`}
        >
          {formatDate(new Date(props.song.createdAt))} από{" "}
          {props.song.createdBy.username}
        </LinkableTableCell>
      ) : null}
      <Table.Cell textAlign="right">
        {user ? (
          <Button.Group icon inverted={props.inverted}>
            <RepertoireToggleButton
              inverted={props.inverted}
              isInRepertoire={
                props.song.users ? props.song.users.length > 0 : false
              }
              id={props.song.id}
            />
            {!props.hideMutations ? (
              <>
                {user.role !== "user" ? (
                  <AddSongModal
                    trigger={<Button inverted={props.inverted} icon="edit" />}
                    mode="EDIT"
                    id={props.song.id}
                  />
                ) : null}
                {user.role === "admin" ? (
                  <DeleteSongModal
                    trigger={<Button  inverted={props.inverted}icon="trash" />}
                    song={props.song}
                  />
                ) : null}
              </>
            ) : null}
          </Button.Group>
        ) : null}
      </Table.Cell>
    </>
  );
}

export default SongRow;
