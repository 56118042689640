import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Segment, Table, Header } from "semantic-ui-react";
import { getUsers } from "../api/users";
import PaginatedTable from "../components/PaginatedTable";
import UserRow from "../components/Users/UserRow";
import { AuthContext } from "../context/AuthContext";

function Users() {
  const { user } = useContext(AuthContext);
  if (!user) {
    throw new Error("Not possible!");
  }

  return (
    <>
      <Helmet>
        <title>Όλοι οι χρήστες - Παρόλες.gr</title>
      </Helmet>
      <Segment>
        <Header>Όλοι οι χρήστες</Header>
        <PaginatedTable
          header={
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>id</Table.HeaderCell>
                <Table.HeaderCell>Username</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Ρόλος</Table.HeaderCell>
                <Table.HeaderCell>Ενεργός</Table.HeaderCell>
                <Table.HeaderCell>Εγγράφηκε</Table.HeaderCell>
                <Table.HeaderCell>Τελευταία ενημέρωση</Table.HeaderCell>
                <Table.HeaderCell>Ενέργειες</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          }
          footer={
            <>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </>
          }
          pageSize={10}
          row={(d) => <UserRow user={d} />}
          queryKey={["users"]}
          queryFunction={getUsers}
          options={{ token: user.token }}
        />
      </Segment>
    </>
  );
}

export default Users;
