import { useState } from "react";
import { Button } from "semantic-ui-react";

interface IProps {
  iconOnHover: string;
  defaultIcon: string;
  onClick?: () => void;
  isLoading?: boolean;
  floated?: "left" | "right";
  label?: string;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  inverted?: boolean;
}

function HoveredIconButton(props: IProps) {
  const [hovered, setHovered] = useState(false);

  return (
    <Button
      inverted={props.inverted}
      icon={!hovered ? props.defaultIcon : props.iconOnHover}
      onClick={props.onClick}
      onMouseLeave={() => {
        setHovered(false);
        if (props.onMouseLeave) {
          props.onMouseLeave();
        }
      }}
      onMouseEnter={() => {
        setHovered(true);
        if (props.onMouseEnter) {
          props.onMouseEnter();
        }
      }}
      loading={props.isLoading}
      floated={props.floated}
      content={props.label}
    />
  );
}

export default HoveredIconButton;
