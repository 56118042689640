import { isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function useError() {
  const navigate = useNavigate();
  function throwError(err: unknown) {
    if (isAxiosError(err)) {
      if (err.response?.status === 404) {
        navigate("/404");
        return;
      }
    }

    if (err instanceof Error) {
      toast(err.message, { type: "error" });
      return;
    }

    toast("Συνέβη κάποιο άγνωστο σφάλμα!", { type: "error" });
    throw err;
  }

  return { throwError };
}

export default useError;
