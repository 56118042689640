import { useCallback, useEffect, useState } from "react";

export default function useLocalStorage(key: string) {
  const [value, setValue] = useState<string | null>(localStorage.getItem(key));

  const getItem = useCallback(() => {
    const value = localStorage.getItem(key);
    setValue(value);
    return value;
  }, [key]);

  useEffect(() => {
    getItem();
  }, [getItem]);

  const setItem = (value: string) => {
    localStorage.setItem(key, value);
    setValue(value);
  };

  const removeItem = () => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return { value, setItem, getItem, removeItem };
}
