import { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Table } from "semantic-ui-react";
import styled, { css } from "styled-components";

interface IProps {
  children: ReactNode;
  to: string;
  inverted?: boolean;
}

function DarkModeLink(
  props: LinkProps & { children?: ReactNode; inverted?: boolean }
) {
  const { inverted, children, to, ...rest } = props;
  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
}

const StyledLink = styled(DarkModeLink)`
  color: black;
  ${(props) =>
    props.inverted &&
    css`
      color: white;
    `};
`;

function LinkableTableCell(props: IProps) {
  return (
    <Table.Cell  style={{ padding: "0px" }}>
      <StyledLink inverted={props.inverted} to={props.to}>
        <div
          style={{
            width: "100%",
            height: "100%",
            padding: "11px",
          }}
        >
          {props.children}
        </div>
      </StyledLink>
    </Table.Cell>
  );
}

export default LinkableTableCell;
