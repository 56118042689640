import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Container, Header, Segment } from "semantic-ui-react";
import { ThemeContext } from "../context/ThemeContext";

function About() {
  const { darkTheme } = useContext(ThemeContext);
  return (
    <>
      <Helmet>
        <title>Πληροφορίες - Παρόλες.gr</title>
        <meta property="og:title" content="Πληροφορίες - Παρόλες.gr" />
      </Helmet>
      <Segment inverted={darkTheme}>
        <Container text>
          <Header inverted={darkTheme} as="h1">
            Πληροφορίες για το Παρόλες.gr
          </Header>
          <p>
            Το <Link to="/">Παρόλες.gr</Link> δημιουργήθηκε συγκρίνοντας τις ήδη
            υπάρχουσες βιβλιοθήκες ελληνικών στίχων στο διαδίκτυο και
            αναγνωρίζοντας ότι οι περισσότερες είναι γεμάτες διαφημίσεις ή/και η
            αναζήτηση σε αυτές είναι δύσκολη.
          </p>
          <p>
            Σκοπός είναι η συγκέντρωση μιας μεγάλης βάσης δεδομένων τραγουδιών
            με τη μέθοδο του crowdsourcing, συμμετοχικά δηλαδή, από τους χρήστες
            της εφαρμογής.
          </p>
          <p>
            Η εφαρμογή δεν έχει στόχο να είναι επιστημονικό εργαλείο και άρα δεν
            είναι προτεραιότητα η τεκμηρίωση των δεδομένων σε αυτή. Παρ' όλα
            αυτά, γίνεται η μέγιστη προσπάθεια τα δεδομένα να είναι ορθά.
          </p>
          <p>
            Η εφαρμογή και τα δεδομένα της, στίχοι, τραγούδια, καλλιτέχνες, θα
            διατίθενται{" "}
            <b>
              για πάντα, ελεύθερα και ανοικτά, χωρίς καμία πληρωμή ή διαφημίσεις
            </b>
            .
          </p>
          <p>
            Απαγορεύεται ρητά η οποιαδήποτε εμπορική εκμετάλλευση της εφαρμογής
            αυτής αλλά και των δεδομένων της.
          </p>
          <Header inverted={darkTheme} as="h2">
            Θέλω να συμβάλω στα δεδομένα!
          </Header>
          <p>
            Προς το παρόν η δυνατότητα προσθήκης και επεξεργασίας των δεδομένων
            στην εφαρμογή (τραγούδια, στίχοι, καλλιτέχνες και άλλα) είναι
            ανοιχτή σε συγκεκριμένους χρήστες για να επιτυγχάνεται η καλύτερη
            τεκμηρίωσή τους και να αποφεύγονται τα όποια λάθη. Σύντομα θα
            ανοίξει σε όλους τους χρήστες της εφαρμογής.
          </p>
          <p>
            Αν βρήκες κάποιο λάθος στα δεδομένα ή θέλεις να προσθέσεις κάποιο
            κομμάτι, στείλε email στη διεύθυνση <b>mail@chrispanag.com</b> και
            θα σου απαντήσουμε το συντομότερο δυνατό.
          </p>
          <Header inverted={darkTheme} as="h2">
            Μελλοντικές επεκτάσεις
          </Header>
          <p>
            Η εφαρμογή αυτή τη στιγμή βρίσκεται σε έκδοση beta, και περιλαμβάνει
            τις βασικές λειτουργίες αναζήτησης και κατηγοριοποίησης των
            τραγουδιών. Στο άμεσο μέλλον, στόχος είναι να προστεθούν διάφορες
            δυνατότητες για την ευκολότερη αναζήτηση και εξερεύνηση του
            ευρετηρίου αλλά και διάφορες κοινωνικές δυνατότητες όπως μεταξύ
            άλλων:
          </p>
          <ul>
            <li>Πολλαπλές λίστες προσωπικών ρεπερτορίων.</li>
            <li>Διαμοιρασμός του προσωπικού ρεπερτορίου με άλλους χρήστες.</li>
            <li>
              Λεπτομερή στατιστικά ανά κομμάτι/καλλιτέχνη αλλά και προσωπικού
              ρεπερτορίου.
            </li>
            <li>Ιστορικό αλλαγών ανά κομμάτι.</li>
            <li>
              Δυνατότητα καταγραφής προτιμώμενου τόνου στο προσωπικό ρεπερτόριο.
            </li>
            <li>
              Περισσότερα μετα-δεδομένα ανά κομμάτι (ρυθμός, είδος και άλλα)
              αλλά και ανά καλλιτέχνη (παρατσούκλι, σχόλια, βιογραφικά στοιχεία
              και άλλα).
            </li>
          </ul>
          <p>
            Παράλληλα, κύρια προτεραιότητα είναι ο εμπλουτισμός της βιβλιοθήκης
            τραγουδιών.
          </p>
          <Header inverted={darkTheme} as="h2">
            Για τον δημιουργό
          </Header>
          <p>
            Η εφαρμογή δημιουργήθηκε το 2023 και την διαχειρίζεται ο Χρήστος
            Παναγιωτακόπουλος:
          </p>
          <ul>
            <li>
              <a href="https://chrispanag.com" target="_blank" rel="noreferrer">
                Προσωπική Ιστοσελίδα (chrispanag.com)
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/chrispanag/"
                target="_blank"
                rel="noreferrer"
              >
                Προφίλ στο LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/chrispanag"
                target="_blank"
                rel="noreferrer"
              >
                Προφίλ στο Twitter
              </a>
            </li>

            <li>
              <a
                href="https://github.com/chrispanag"
                target="_blank"
                rel="noreferrer"
              >
                Προφίλ στο Github
              </a>
            </li>
          </ul>
          <p>
            Μπορείτε να επικοινωνήσετε μαζί του στη διεύθυνση{" "}
            <b>mail@chrispanag.com</b>.
          </p>
          <Header inverted={darkTheme} as="h2">
            Γιατί "Παρόλες";
          </Header>
          <p>
            Σύμφωνα με{" "}
            <a
              href="https://lexikolefkadas.gr/parola-es/"
              target="_blank"
              rel="noreferrer"
            >
              το λεξικό του Λευκαδίτικου Ιδιώματος:
            </a>
          </p>
          <p>
            <i>
              <b>παρόλα -ες</b>: κουβέντα, συζήτηση, πειραχτικά λόγια. φράσεις:
              “πολλές παρόλες, βλέπω, έχετε …” – “Μου ρίχνει παρόλες, όταν με
              συναντάει, και δε θα τα πάμε καλά”. _ “Με σφάζει με τις παρόλες
              του” – Ασ΄τον αυτόνε, αν δεν πει την παρόλα του, θα σκάσει”.
            </i>
          </p>
          <p>Επίσης, στα γαλλικά η λέξη "στίχοι" είναι "paroles".</p>
          <Header inverted={darkTheme} as="h2">
            Ευχαριστίες
          </Header>
          <p>
            Ένα μεγάλο μέρος των δεδομένων που υπάρχουν στην εφαρμογή έχουν
            παρθεί από τις παρακάτω πηγές:
          </p>
          <ul>
            <li>
              <a
                href="https://rebetiko.sealabs.net"
                target="_blank"
                rel="noreferrer"
              >
                Η ρεμπετοσελίδα (rebetiko.sealabs.net)
              </a>
            </li>
            <li>
              <a href="https://rembetiko.gr" target="_blank" rel="noreferrer">
                Το ρεμπέτικο φόρουμ (rembetiko.gr)
              </a>
            </li>
            <li>Εργογραφίες καλλιτεχνών του Γιάννη Τζανίδη</li>
          </ul>

          <p>Τους ευχαριστούμε πολύ.</p>

          <p>
            Το λογότυπο της σελίδας πάρθηκε από εδώ:{" "}
            <a
              href="https://www.flaticon.com/free-icons/song-lyrics"
              target="_blank"
              rel="noreferrer"
              title="song lyrics icons"
            >
              Song lyrics icons created by Karacis - Flaticon
            </a>
          </p>
        </Container>
      </Segment>
    </>
  );
}

export default About;
