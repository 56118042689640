import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Button, Modal, Form, Select, Checkbox } from "semantic-ui-react";
import { IUpdateUserOptions, IUser, updateUser } from "../../api/users";
import { AuthContext } from "../../context/AuthContext";

interface IProps {
  trigger: React.ReactNode;
  user: IUser;
}

interface IRoleOptions {
  key: IUser["role"];
  value: IUser["role"];
  text: string;
}

const roleOptions: IRoleOptions[] = [
  { key: "admin", value: "admin", text: "Διαχειριστής" },
  { key: "user", value: "user", text: "Χρήστης" },
  { key: "editor", value: "editor", text: "Συντάκτης" },
];

function EditUserModal(props: IProps) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(props.user.email);
  const [username, setUsername] = useState(props.user.username);
  const [role, setRole] = useState(props.user.role);
  const [active, setActive] = useState(props.user.active);
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const sendDataMutation = useMutation(
    (editObject: IUpdateUserOptions["editObject"]) => {
      if (!user) {
        throw new Error("Not authenticated!");
      }

      return updateUser({ id: props.user.id, token: user.token, editObject });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        toast(`Ο χρήστης "${props.user.username}" επεξεργάστηκε επιτυχώς.`, {
          type: "success",
        });
      },
    }
  );

  const sendDataEdit = async () => {
    const editObject: IUpdateUserOptions["editObject"] = {};
    if (email !== props.user.email) {
      editObject.email = email;
    }
    if (username !== props.user.username) {
      editObject.username = username;
    }
    if (role !== props.user.role) {
      editObject.role = role;
    }
    if (active !== props.user.active) {
      editObject.active = active;
    }
    await sendDataMutation.mutateAsync(editObject);
    setEmail(props.user.email);
    setUsername(props.user.username);
    setUsername(props.user.role);
    setOpen(false);
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
    >
      <Modal.Header>Επεξεργασία χρήστη</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Email</label>
            <input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(ev) => setEmail(ev.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Username</label>
            <input
              placeholder="Email"
              type="email"
              value={username}
              onChange={(ev) => setUsername(ev.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Ρόλος</label>
            <Select
              placeholder="Επιλογή ρόλου"
              options={roleOptions}
              value={role}
              onChange={(ev, data) => setRole(data.value as IUser["role"])}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Ενεργός"
              toggle
              checked={active}
              onChange={(ev, data) => setActive(Boolean(data.checked))}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Ακύρωση
        </Button>
        <Button
          content="Επεξεργασία"
          labelPosition="right"
          icon="checkmark"
          onClick={() => sendDataEdit()}
          loading={false}
          positive
          disabled={false}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default EditUserModal;
