import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Header, Segment } from "semantic-ui-react";
import SearchSongs from "../components/songs/SearchSongs";
import SongTable from "../components/songs/SongTable";
import { ThemeContext } from "../context/ThemeContext";

function Songs() {
  const { darkTheme } = useContext(ThemeContext);
  return (
    <>
      <Helmet>
        <title>Τραγούδια - Παρόλες.gr</title>
        <meta
          name="description"
          content="Αναζήτησε στίχους για τα αγαπημένα σου ελληνικά τραγούδια εύκολα και γρήγορα χωρίς διαφημίσεις!"
        />
        <meta property="og:title" content="Τραγούδια - Παρόλες.gr" />
      </Helmet>
      <Segment inverted={darkTheme}>
        <Header>Αναζήτηση τραγουδιού</Header>
        <p>Γράψε τον τίτλο ή ένα τμήμα από τους στίχους κάποιου τραγουδιού.</p>
        <SearchSongs fluid />
      </Segment>
      <Segment inverted={darkTheme}>
        <Header>Τελευταίες προσθήκες στη βιβλιοθήκη</Header>
        <SongTable inverted={darkTheme} showCreatedAt showAddButton />
      </Segment>
    </>
  );
}

export default Songs;
