import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import useLocalStorage from "./useLocalStorage";

export type Theme = "dark" | "light";

function useTheme() {
  const { value, setItem } = useLocalStorage("theme");
  const [theme, setTheme] = useState<Theme>("light");
  const mediaQueryPrefersLight = useMediaQuery({
    query: `(prefers-color-scheme: light)`,
  });
  const mediaQueryPrefersDark = useMediaQuery({
    query: `(prefers-color-scheme: dark)`,
  });

  const setUserTheme = (theme: Theme | "system") => {
    setItem(theme);
  };

  useEffect(() => {
    if (value === null) {
      setTheme("light");
      return;
    }
    if (value === "dark") {
      setTheme("dark");
      return;
    }
    if (value === "light") {
      setTheme("light");
      return;
    }
    if (mediaQueryPrefersDark) {
      setTheme("dark");
      return;
    }
    if (mediaQueryPrefersLight) {
      setTheme("light");
      return;
    }
  }, [mediaQueryPrefersDark, mediaQueryPrefersLight, value]);

  return {
    theme,
    setUserTheme,
    userTheme: (value as Theme | "system") ?? "light",
    darkTheme: theme === "dark",
    lightTheme: theme === "light",
  };
}

export default useTheme;
