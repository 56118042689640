import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useMatches, useSearchParams } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Header,
  Loader,
  Segment,
} from "semantic-ui-react";
import { getPerson } from "../api/persons";
import AddPersonModal from "../components/persons/AddPersonModal";
import DeletePersonModal from "../components/persons/DeletePersonModal";
import SongTable from "../components/songs/SongTable";
import { AuthContext } from "../context/AuthContext";
import { ThemeContext } from "../context/ThemeContext";
import { formatDate } from "../utils";

function rolesSwitch(role: "composer" | "songwriter") {
  switch (role) {
    case "composer":
      return "Συνθέτης";
    case "songwriter":
      return "Στιχουργός";
    default:
      return role;
  }
}

const defaultRoleOptions: DropdownItemProps[] = [
  { text: "Όλες οι ιδιότητες", value: "all", key: "all" },
  { text: "Συνθέτης", value: "composer", key: "composer" },
  { text: "Στιχουργός", value: "songwriter", key: "songwriter" },
];

function Person() {
  const { darkTheme } = useContext(ThemeContext);
  const [matched] = useMatches();
  const { id } = matched.params;
  if (id === undefined) {
    throw new Error("Impossible!");
  }
  const composer = parseInt(id);

  const [searchParams, setSearchParams] = useSearchParams();
  const role = searchParams.get("role") ?? "all";

  const [roles, setRoles] = useState<string | null>(null);
  const [roleOptions, setRoleOptions] = useState(defaultRoleOptions);
  const { user } = useContext(AuthContext);
  const { data } = useQuery(["person", id], () =>
    getPerson(composer, user?.token)
  );

  useEffect(() => {
    if (data?.roles) {
      const roles = data.roles.split(",");
      setRoles(roles.map(rolesSwitch as any).join(", "));
      setRoleOptions((roleOptions) =>
        roleOptions.map((r) => ({
          ...r,
          disabled: !roles.includes(r.value as string) && r.value !== "all",
        }))
      );
    }
  }, [data]);

  const composerFullName = () => `${data?.lastName} ${data?.firstName}`;
  return data ? (
    <>
      <Helmet>
        <title>{composerFullName()} - Παρόλες.gr</title>
        <meta
          name="description"
          content="Αναζήτησε στίχους για τα αγαπημένα σου ελληνικά τραγούδια εύκολα και γρήγορα χωρίς διαφημίσεις!"
        />
        <meta
          property="og:title"
          content={`${composerFullName()} - Παρόλες.gr`}
        />
        <meta property="og:type" content="profile" />
        <meta property="profile:first_name" content={data?.firstName} />
        <meta property="profile:last_name" content={data?.lastName} />
      </Helmet>
      <Segment inverted={darkTheme}>
        <Header>{`Τραγούδια του ${composerFullName()}`}</Header>
        <Dropdown
          selection
          options={roleOptions}
          labeled
          value={role}
          onChange={(e, data) =>
            setSearchParams((searchParams) => {
              searchParams.set("role", data.value as string);
              searchParams.delete("offset");
              return searchParams;
            })
          }
        />
        <SongTable
          inverted={darkTheme}
          artist={composer}
          artistRole={role as "composer" | "songwriter" | "all"}
        />
      </Segment>
      <Segment inverted={darkTheme}>
        <Header as="h4">Περισσότερα στοιχεία</Header>
        <p>Τραγούδια που συμμετέχει: {data.songCount}</p>
        <p>
          {!roles
            ? "Δεν εμφανίζεται με καμία ιδιότητα στη βιβλιοθήκη"
            : `Εμφανίζεται με τις ιδιότητες: ${roles}`}
        </p>
        <Header as="h4">Πληροφοριές καταχώρησης</Header>
        <p>Τελευταία αλλαγή: {formatDate(new Date(data.modifiedOn))}</p>
        <p>Δημιουργήθηκε: {formatDate(new Date(data.createdAt))}</p>
        {user && user.role !== "user" ? (
          <AddPersonModal
            trigger={<Button inverted={darkTheme} icon="edit" content="Επεξεργασία" />}
            person={data}
            mode="EDIT"
          />
        ) : null}
        {user && user.role === "admin" ? (
          <DeletePersonModal
            trigger={<Button inverted={darkTheme} icon="trash" content="Διαγραφή" />}
            person={data}
          />
        ) : null}
      </Segment>
    </>
  ) : (
    <Segment>
      <Loader active inline="centered" />
    </Segment>
  );
}

export default Person;
