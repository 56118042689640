import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";

import { AuthContext } from "./context/AuthContext";
import useLogin from "./hooks/useLogin";
import Songs from "./pages/Songs";
import Persons from "./pages/Persons";
import Repertoire from "./pages/Repertoire";
import Song from "./pages/Song";
import { QueryClient, QueryClientProvider } from "react-query";
import NotFound from "./pages/NotFound";
import DesktopLayout from "./layouts/DesktopLayout";
import { useMediaQuery } from "react-responsive";
import MobileLayout from "./layouts/MobileLayout";
import Explore from "./pages/Explore";
import ProtectedRoute from "./components/ProtectedRoute";
import UserSettings from "./pages/UserSettings";
import Users from "./pages/Users";
import VerifyEmail from "./pages/VerifyEmail";
import Person from "./pages/Person";
import RegistrationSuccess from "./pages/RegistrationSuccess";
import ForgotPasswordEmailSent from "./pages/ForgotPasswordEmailSent";
import ResetPassword from "./pages/ResetPassword";
import { HelmetProvider } from "react-helmet-async";
import About from "./pages/About";
import QueryClientDefaults from "./components/QueryClientsDefaults";
import useTheme from "./hooks/useTheme";
import { ThemeContext } from "./context/ThemeContext";
import { createGlobalStyle } from "styled-components";

// Create a client
const queryClient = new QueryClient();

const GlobalStyle = createGlobalStyle<{ darkMode?: boolean }>`
  body {
    background-color: ${(props) =>
      props.darkMode ? "rgb(39, 39, 39)" : "#FCFFFF"};
  }
`;

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const router = createBrowserRouter([
    {
      element: (
        <QueryClientDefaults>
          {isDesktopOrLaptop ? <DesktopLayout /> : <MobileLayout />}
        </QueryClientDefaults>
      ),
      children: [
        {
          path: "/",
          element: <Explore />,
          errorElement: <NotFound />,
        },
        {
          path: "/songs",
          element: <Songs />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/verify_email",
          element: <VerifyEmail />,
        },
        {
          path: "/registration_success",
          element: <RegistrationSuccess />,
        },
        {
          path: "/forgot_password_success",
          element: <ForgotPasswordEmailSent />,
        },
        {
          path: "/reset_password",
          element: <ResetPassword />,
        },
        {
          path: "/persons",
          element: <Persons />,
        },
        {
          path: "/persons/:id",
          element: <Person />,
        },
        {
          path: "/repertoire",
          element: (
            <ProtectedRoute>
              <Repertoire />
            </ProtectedRoute>
          ),
        },
        {
          path: "/repertoire/:id",
          element: (
            <ProtectedRoute>
              <Repertoire />
            </ProtectedRoute>
          ),
        },
        {
          path: "/settings",
          element: (
            <ProtectedRoute>
              <UserSettings />
            </ProtectedRoute>
          ),
        },
        {
          path: "/users",
          element: (
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          ),
        },
        {
          path: "/songs/:id",
          element: <Song />,
        },
        {
          path: "/*",
          element: <NotFound />,
        },
      ],
    },
  ]);
  const { user, login, logout } = useLogin();
  const { theme, setUserTheme, userTheme } = useTheme();

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={{ user, login, logout }}>
          <ThemeContext.Provider
            value={{
              userTheme,
              setUserTheme,
              theme,
              darkTheme: theme === "dark",
              lightTheme: theme === "light",
            }}
          >
            <GlobalStyle darkMode={theme === "dark"} />
            <RouterProvider router={router} />
          </ThemeContext.Provider>
        </AuthContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
