import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Dropdown, Form, Message, TextArea } from "semantic-ui-react";
import { IPerson } from "../../api/interfaces";
import { searchPersons } from "../../api/persons";
import { searchSongs } from "../../api/songs";
import useDebounce from "../../hooks/useDebounce";
import AddPersonModal from "../persons/AddPersonModal";

interface IProps {
  title: string;
  setTitle: (s: string) => void;
  altTitle: string;
  setAltTitle: (s: string) => void;
  year: string;
  setYear: (s: string) => void;
  composer: number | null;
  setComposer: (s: number) => void;
  songwriter: number | null;
  setSongwriter: (s: number) => void;
  lyrics: string;
  setLyrics: (s: string) => void;
  initialComposers?: Pick<IPerson, "id" | "firstName" | "lastName">[];
  isEdit?: boolean;
}

function SongForm(props: IProps) {
  const [composerOptions, setComposerOptions] = useState<
    Array<{ key: number; text: string; value: number | undefined }>
  >([]);
  const [songwriterOptions, setSongwriterOptions] = useState<
    Array<{ key: number; text: string; value: number | undefined }>
  >([]);
  const [composerQuery, setComposerQuery] = useState("");
  const debouncedComposerQuery = useDebounce(composerQuery, 250);
  const [songwriterQuery, setSongwriterQuery] = useState("");
  const debouncedSongwriterQuery = useDebounce(songwriterQuery, 250);

  const debouncedTitleQuery = useDebounce(props.title.trim(), 1000);
  const { data: responseSongs } = useQuery(
    ["songs", debouncedTitleQuery],
    () => searchSongs(debouncedTitleQuery),
    { enabled: debouncedTitleQuery.length > 0 && !props.isEdit }
  );

  const { data: composers, isRefetching: composersIsRefetching } = useQuery(
    ["persons", debouncedComposerQuery],
    () => searchPersons({ query: debouncedComposerQuery }),
    {
      enabled: debouncedComposerQuery.length > 0,
      placeholderData: props.initialComposers ?? [],
    }
  );

  const { data: songwriters, isRefetching: songwritersIsRefetching } = useQuery(
    ["persons", debouncedSongwriterQuery],
    () => searchPersons({ query: debouncedSongwriterQuery }),
    {
      enabled: debouncedSongwriterQuery.length > 0,
      placeholderData: props.initialComposers ?? [],
    }
  );

  useEffect(() => {
    if (composers && composers.length > 0) {
      const options = [
        { key: -1, text: "Άγνωστος", value: undefined },
        ...composers.map((p) => ({
          key: p.id,
          text: `${p.lastName} ${p.firstName}`,
          value: p.id,
        })),
      ];
      setComposerOptions(options);
    }
  }, [composers]);

  useEffect(() => {
    if (songwriters && songwriters.length > 0) {
      const options = [
        { key: -1, text: "Άγνωστος", value: undefined },
        ...songwriters.map((p) => ({
          key: p.id,
          text: `${p.lastName} ${p.firstName}`,
          value: p.id,
        })),
      ];
      setSongwriterOptions(options);
    }
  }, [songwriters]);

  return (
    <Form
      warning={
        props.title.length > 0 && responseSongs && responseSongs.length > 0
      }
    >
      <Form.Field required>
        <label>Τίτλος</label>
        <input
          placeholder="Τίτλος"
          value={props.title}
          onChange={(ev) => props.setTitle(ev.target.value)}
        />
      </Form.Field>
      <Message
        warning
        header="Υπάρχουν τραγούδια με παρόμοιους τίτλους..."
        list={responseSongs?.map((s) => (
          <li>
            <Link to={`/songs/${s.id}`}>{s.title}</Link>
          </li>
        ))}
      />
      <Form.Field>
        <label>Εναλλακτικός τίτλος</label>
        <input
          placeholder="Εναλλακτικός τίτλος"
          value={props.altTitle}
          onChange={(ev) => props.setAltTitle(ev.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Έτος κυκλοφορίας</label>
        <input
          placeholder="Έτος κυκλοφορίας"
          value={props.year}
          onChange={(ev) => props.setYear(ev.target.value)}
        />
      </Form.Field>
      <Form.Group style={{ position: "relative" }}>
        <Form.Field width="15">
          <label>Συνθέτης</label>
          <Dropdown
            placeholder="Άγνωστος"
            fluid
            search
            selection
            lazyLoad
            loading={
              composersIsRefetching || debouncedComposerQuery !== composerQuery
            }
            value={props.composer ?? undefined}
            onChange={(e, data) => props.setComposer(data.value as number)}
            options={composerOptions}
            noResultsMessage="Κανένα αποτέλεσμα"
            onSearchChange={(ev, { searchQuery }) =>
              setComposerQuery(searchQuery)
            }
          />
        </Form.Field>
        <AddPersonModal
          mode="CREATE"
          trigger={
            <Form.Button
              style={{
                top: "22px",
                position: "absolute",
                height: "38px",
                width: "38px",
              }}
              icon="add"
              primary
              size="small"
            />
          }
        />
      </Form.Group>
      <Form.Group style={{ position: "relative" }}>
        <Form.Field width="15">
          <label>Στιχουργός</label>
          <Dropdown
            placeholder="Άγνωστος"
            fluid
            search
            selection
            lazyLoad
            loading={
              songwritersIsRefetching ||
              debouncedSongwriterQuery !== songwriterQuery
            }
            value={props.songwriter ?? undefined}
            onChange={(e, data) => props.setSongwriter(data.value as number)}
            options={songwriterOptions}
            noResultsMessage="Κανένα αποτέλεσμα"
            onSearchChange={(ev, { searchQuery }) =>
              setSongwriterQuery(searchQuery)
            }
          />
        </Form.Field>
        <AddPersonModal
          mode="CREATE"
          trigger={
            <Form.Button
              style={{
                top: "22px",
                position: "absolute",
                height: "38px",
                width: "38px",
              }}
              icon="add"
              primary
              size="small"
            />
          }
        />
      </Form.Group>
      <Form.Field
        required
        control={TextArea}
        rows={30}
        value={props.lyrics}
        onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) =>
          props.setLyrics(ev.target.value)
        }
        placeholder="Στίχοι"
        label="Στίχοι"
      >
        <TextArea style={{ minHeight: 500 }}></TextArea>
      </Form.Field>
    </Form>
  );
}

export default SongForm;
