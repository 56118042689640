import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Search, SearchProps, SearchResultProps } from "semantic-ui-react";
import { searchSongs } from "../../api/songs";
import { AuthContext } from "../../context/AuthContext";
import useDebounce from "../../hooks/useDebounce";

interface IProps {
  fluid?: SearchProps["fluid"];
  style?: Record<string, string>;
  size?: SearchProps["size"];
}

function searchResult(props: SearchResultProps) {
  const description = (
    <>
      ...{" "}
      {props.description?.split(/(\s+)/).map((d) => (
        <>
          {d.includes("<b>") ? (
            <b>{d.replace(`<b>`, "").replace("</b>", "")}</b>
          ) : (
            d
          )}{" "}
        </>
      ))}
      ...
    </>
  );
  return (
    <div>
      <div className="title">{props.title}</div>
      <div className="description">{props.composer}</div>
      <div className="description">
        <i>{description}</i>
      </div>
    </div>
  );
}

function SearchSongs(props: IProps) {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<Array<SearchResultProps>>([]);
  const debouncedQuery = useDebounce(query, 250);
  const { data: responseSongs, isFetching } = useQuery(
    ["songs", debouncedQuery, ...(user?.token ? ["user"] : [])],
    () => searchSongs(debouncedQuery, user?.token),
    { enabled: debouncedQuery.length > 0 }
  );

  useEffect(() => {
    if (!responseSongs) {
      return;
    }
    const res: SearchResultProps[] = responseSongs.map((r) => {
      const composer = r.persons.find((p) => p.role === "composer")?.person;
      return {
        id: r.id,
        title: `${r.title}${r.altTitle ? ` (${r.altTitle})` : ""}`,
        description: r.headline,
        composer:
          (composer
            ? `${composer.lastName} ${composer.firstName}`
            : "Άγνωστος") + (r.year ? ` - ${r.year}` : ""),
      };
    });

    setResults(res);
  }, [responseSongs]);

  return (
    <Search
      placeholder="Τίτλος ή στίχος..."
      noResultsMessage="Δεν βρέθηκαν αποτελέσματα..."
      fluid={props.fluid}
      size={props.size}
      className="mainSearch"
      resultRenderer={searchResult}
      style={props.style}
      results={results}
      loading={isFetching || query !== debouncedQuery}
      onSearchChange={(ev, data) => setQuery(data.value ?? "")}
      onResultSelect={(ev, data) => {
        navigate(`/songs/${data.result.id}`);
      }}
    />
  );
}

export default SearchSongs;
