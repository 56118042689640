import axios from "./axios";

export enum LoginError {
  UNAUTHORIZED = "UNAUTHORIZED",
}

export async function postLogin(
  email: string,
  password: string
): Promise<
  | {
      access_token: string;
      username: string;
      role: "admin" | "editor" | "user";
    }
  | LoginError.UNAUTHORIZED
> {
  const res = await axios({
    method: "post",
    url: `/auth/login`,
    data: { username: email, password },
  });

  if (res.status >= 400) {
    return LoginError.UNAUTHORIZED;
  }

  return res.data;
}

export async function postRegister(
  email: string,
  password: string,
  username: string,
  captchaToken: string
) {
  const res = await axios({
    method: "post",
    url: `/users`,
    data: { email, password, username, captchaToken },
  });

  return res.data;
}
