import { IUserData } from "../context/AuthContext";
import useLocalStorage from "./useLocalStorage";

export default function useLogin() {
  const { value, setItem, removeItem } = useLocalStorage("user");

  const login = (data: IUserData) => {
    setItem(JSON.stringify(data));
  };

  const logout = () => {
    removeItem();
  };

  return {
    user: value ? (JSON.parse(value) as IUserData) : null,
    login,
    logout,
  };
}
